/**
 * Fubi icon.
 */

import { icons } from '@/assets/icons';

export default {
  name: 'Icon',
  props: {
    icon: String,
    fill: { type: String, default: 'white' },
    stroke: { type: String, default: 'white' },
    emit: { type: String, default: '' },
  },
  computed: {
    iconComponent: function() {
      const [category, genre] = this.icon.split(':');
      return (
        !!icons[category.toLowerCase()] &&
        icons[category.toLowerCase()][genre.toLowerCase()]
      );
    },
    style() {
      const s = {};
      if (this.fill) {
        s.fill = this.fill;
      }
      if (this.stroke) {
        s.stroke = this.stroke;
      }
      return s;
    },
  },
  methods: {
    /**
     * Cause the specified emit event to be emmitted when the icon is clicked.
     */
    clicked() {
      if (this.emit) {
        this.$emit(this.emit);
      }
    },
  },
};
