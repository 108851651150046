/**
 * Options for the "attitudes" section of the Wizard.
 * POSITIVE is mapped to "agrees with".
 */

export default [
  {
    positiveText: 'Ambitious and driven',
    negativeText: 'Relaxed and laid back',
    value: 'AMBITIOUS AND DRIVEN',
  },
  {
    positiveText: 'Keeps up with fashions',
    negativeText: 'Has own style',
    value: 'KEEP UP WITH FASHIONS',
  },
  {
    positiveText: 'Influenced by social media',
    negativeText: 'Sceptical of social media',
    value: 'INFLUENCED BY SOCIAL MEDIA',
  },
  {
    positiveText: 'Heavily influenced by the Internet',
    negativeText: 'Sceptical of the Internet',
    value: 'HEAVILY INFLUENCED BY INTERNET',
  },
  {
    positiveText: 'Early adopter',
    negativeText: 'Late adopter',
    value: 'OPEN TO NEW TECH',
  },
  {
    positiveText: 'Shares interests online',
    negativeText: 'Undersharer online',
    value: 'SHARE INTERESTS ONLINE',
  },
  {
    positiveText: 'Driven by status',
    negativeText: 'Comfortable with their status',
    value: 'STATUS DRIVEN',
  },
  {
    positiveText: 'Thrives on feedback',
    negativeText: 'Chooses their own path',
    value: 'THRIVE ON FEEDBACK',
  },
  {
    positiveText: 'Volunteers',
    negativeText: 'Does not volunteer',
    value: 'VOLUNTEERS',
  },
  {
    positiveText: 'Lives a sustainable life',
    negativeText: 'Eco sceptic',
    value: 'LIVE A SUSTAINABLE LIFE',
  },
  {
    positiveText: 'Driven by popularity',
    negativeText: 'Internally motivated',
    value: 'POPULARITY DRIVEN',
  },
  {
    positiveText: 'Overwhelmed by social change',
    negativeText: 'Thrives on social change',
    value: 'PACE OF CHANGE OVERWHELMING',
  },
  {
    positiveText: 'Networker',
    negativeText: 'Lone wolf',
    value: 'NETWORKER',
  },
  {
    positiveText: 'Sceptical of online sources',
    negativeText: 'Trusts online sources',
    value: 'SCEPTICAL OF SOURCES ONLINE',
  },
  {
    positiveText: 'Comfortable expressing opinions online',
    negativeText: 'Reluctant to express opinions online',
    value: 'INTERNET A SAFE PLACE TO EXPRESS OPINIONS',
  },
  {
    positiveText: 'Activity organiser',
    negativeText: 'Activity participant',
    value: 'ACTIVITY ORGANISER',
  },
  {
    positiveText: 'Consults with friends and family',
    negativeText: 'Self reliant',
    value: 'CONSULTS FRIENDS AND FAMILY',
  },
  {
    positiveText: 'Prefers print media to online',
    negativeText: 'Prefers online media to print',
    value: 'PREFER PRINT TO ONLINE',
  },
  {
    positiveText: 'Time-poor',
    negativeText: 'Lots of free time',
    value: 'TIME POOR',
  },
  {
    positiveText: 'Family-focused',
    negativeText: 'Self-focused',
    value: 'FAMILY FOCUSED',
  },
  {
    positiveText: 'Sceptical of branded content',
    negativeText: 'Brand friendly',
    value: 'SCEPTICAL OF BRAND CONTENT',
  },
  {
    positiveText: 'Worries what people think',
    negativeText: 'Self confident',
    value: 'WORRY WHAT PEOPLE THINK',
  },
  {
    positiveText: 'Politically conservative',
    negativeText: 'Politically liberal',
    value: 'POLITICALLY CONSERVATIVE',
  },
  {
    positiveText: 'Socially conservative',
    negativeText: 'Socially liberal',
    value: 'SOCIALLY CONSERVATIVE',
  },
];
