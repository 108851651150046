import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en_gb from '@/i18n/en_gb';

Vue.use(VueI18n);

const messages = {
  en_gb,
};

export const i18n = new VueI18n({
  locale: 'en_gb',
  fallbackLocale: 'en_gb',
  messages,
});
