const baseData = [
  {
    text: 'Argentina',
    value: 'Argentina',
    regions: [
      {
        text: 'Buenos Aires',
        value: 'Buenos Aires',
      },
      {
        text: 'Norte-Grande',
        value: 'Norte-Grande',
      },
      {
        text: 'Centro',
        value: 'Centro',
      },
      {
        text: 'Nuevo Cuyo',
        value: 'Nuevo Cuyo',
      },
      {
        text: 'Patagonia',
        value: 'Patagonia',
      },
    ],
  },
  {
    text: 'Australia',
    value: 'Australia',
    regions: [
      {
        text: 'New South Wales',
        value: 'New South Wales',
      },
      {
        text: 'Victoria',
        value: 'Victoria',
      },
      {
        text: 'Tasmania',
        value: 'Tasmania',
      },
      {
        text: 'Queensland',
        value: 'Queensland',
      },
      {
        text: 'Northern Territory',
        value: 'Northern Territory',
      },
      {
        text: 'Western Australia',
        value: 'Western Australia',
      },
      {
        text: 'South Australia',
        value: 'South Australia',
      },
    ],
  },
  {
    text: 'Brazil',
    value: 'Brazil',
    regions: [
      {
        text: 'North',
        value: 'North',
      },
      {
        text: 'North-east',
        value: 'North-east',
      },
      {
        text: 'Central-west',
        value: 'Central-west',
      },
      {
        text: 'South-east',
        value: 'South-east',
      },
      {
        text: 'South',
        value: 'South',
      },
    ],
  },
  {
    text: 'Canada',
    value: 'Canada',
    regions: [
      {
        text: 'Quebec',
        value: 'Quebec',
      },
      {
        text: 'Atlantic Canada',
        value: 'Atlantic Canada',
      },
      {
        text: 'Ontario',
        value: 'Ontario',
      },
      {
        text: 'The North',
        value: 'The North',
      },
      {
        text: 'Western Canada',
        value: 'Western Canada',
      },
    ],
  },
  {
    text: 'China',
    value: 'China',
    regions: [
      {
        text: 'Beijing',
        value: 'Beijing',
      },
      {
        text: 'Shanghai',
        value: 'Shanghai',
      },
      {
        text: 'Guangzhou',
        value: 'Guangzhou',
      },
      {
        text: 'Wuhan',
        value: 'Wuhan',
      },
      {
        text: 'Hangzhou',
        value: 'Hangzhou',
      },
      {
        text: 'Chengdu',
        value: 'Chengdu',
      },
    ],
  },
  {
    text: 'France',
    value: 'France',
    regions: [
      {
        text: "SOUTH EAST (Auvergne-Rhône-Alpes, Provence-Alpes-Côte d'Azur)",
        value:
          "SOUTH EAST (Auvergne-Rh&#244;ne-Alpes, Provence-Alpes-C&#244;te d'Azur)",
      },
      {
        text: 'IDF (Île-de-France)',
        value: 'IDF (&#206;le-de-France)',
      },
      {
        text: 'SOUTH WEST (Occitanie, Nouvelle-Aquitaine)',
        value: 'SOUTH WEST (Occitanie, Nouvelle-Aquitaine)',
      },
      {
        text: 'WEST (Centre-Val de Loire, Brittany, Pays de la Loire)',
        value: 'WEST (Centre-Val de Loire, Brittany, Pays de la Loire)',
      },
      {
        text: 'NORTH (Normandy, Hauts-de-France)',
        value: 'NORTH (Normandy, Hauts-de-France)',
      },
      {
        text: 'EAST (Grand Est, Bourgogne-Franche-Comté)',
        value: 'EAST (Grand Est, Bourgogne-Franche-Comt&#233;)',
      },
    ],
  },
  {
    text: 'Germany',
    value: 'Germany',
    regions: [
      {
        text: 'Hamburg',
        value: 'Hamburg',
      },
      {
        text: 'Bremen',
        value: 'Bremen',
      },
      {
        text: 'Schleswig-Holstein',
        value: 'Schleswig-Holstein',
      },
      {
        text: 'Niedersachsen',
        value: 'Niedersachsen',
      },
      {
        text: 'Nordrhein-Westfalen',
        value: 'Nordrhein-Westfalen',
      },
      {
        text: 'Hessen',
        value: 'Hessen',
      },
      {
        text: 'Rheinland-Pfalz',
        value: 'Rheinland-Pfalz',
      },
      {
        text: 'Saarland',
        value: 'Saarland',
      },
      {
        text: 'Baden-Württemberg',
        value: 'Baden-W&#252;rttemberg',
      },
      {
        text: 'Bayern',
        value: 'Bayern',
      },
      {
        text: 'Berlin',
        value: 'Berlin',
      },
      {
        text: 'Brandenburg',
        value: 'Brandenburg',
      },
      {
        text: 'Sachsen-Anhalt',
        value: 'Sachsen-Anhalt',
      },
      {
        text: 'Mecklenburg-Vorpommern',
        value: 'Mecklenburg-Vorpommern',
      },
      {
        text: 'Thüringen',
        value: 'Th&#252;ringen',
      },
      {
        text: 'Sachsen',
        value: 'Sachsen',
      },
    ],
  },
  {
    text: 'India',
    value: 'India',
    regions: [
      {
        text: 'Central India',
        value: 'Central India',
      },
      {
        text: 'East India',
        value: 'East India',
      },
      {
        text: 'North India',
        value: 'North India',
      },
      {
        text: 'Northeast India',
        value: 'Northeast India',
      },
      {
        text: 'South India',
        value: 'South India',
      },
      {
        text: 'Western India',
        value: 'Western India',
      },
    ],
  },
  {
    text: 'Indonesia',
    value: 'Indonesia',
    regions: [
      {
        text: 'Java',
        value: 'Java',
      },
      {
        text: 'Sumatra',
        value: 'Sumatra',
      },
      {
        text: 'Other regions',
        value: 'Other regions',
      },
    ],
  },
  {
    text: 'Italy',
    value: 'Italy',
    regions: [
      {
        text: 'Centre',
        value: 'Centre',
      },
      {
        text: 'North West',
        value: 'North West',
      },
      {
        text: 'North East',
        value: 'North East',
      },
      {
        text: 'South & Islands',
        value: 'South & Islands',
      },
    ],
  },
  {
    text: 'Japan',
    value: 'Japan',
    regions: [
      {
        text: 'Hokkaido',
        value: 'Hokkaido',
      },
      {
        text: 'Tohoku',
        value: 'Tohoku',
      },
      {
        text: 'Kanto',
        value: 'Kanto',
      },
      {
        text: 'Chubu',
        value: 'Chubu',
      },
      {
        text: 'Kansai',
        value: 'Kansai',
      },
      {
        text: 'Chugoku',
        value: 'Chugoku',
      },
      {
        text: 'Shikoku',
        value: 'Shikoku',
      },
      {
        text: 'Kyushu',
        value: 'Kyushu',
      },
    ],
  },
  {
    text: 'Korea',
    value: 'Korea',
    regions: [
      {
        text: 'Seoul',
        value: 'Seoul',
      },
      {
        text: 'Incheon',
        value: 'Incheon',
      },
      {
        text: 'Gyeonggi',
        value: 'Gyeonggi',
      },
      {
        text: 'Gangwon',
        value: 'Gangwon',
      },
      {
        text: 'South Gyeongsang',
        value: 'South Gyeongsang',
      },
      {
        text: 'North Gyeongsang',
        value: 'North Gyeongsang',
      },
      {
        text: 'Daegu',
        value: 'Daegu',
      },
      {
        text: 'Ulsan',
        value: 'Ulsan',
      },
      {
        text: 'Busan',
        value: 'Busan',
      },
      {
        text: 'South Jeolla',
        value: 'South Jeolla',
      },
      {
        text: 'North Jeolla',
        value: 'North Jeolla',
      },
      {
        text: 'Gwangju',
        value: 'Gwangju',
      },
      {
        text: 'Jeju',
        value: 'Jeju',
      },
      {
        text: 'South Chungcheong',
        value: 'South Chungcheong',
      },
      {
        text: 'North Chungcheong',
        value: 'North Chungcheong',
      },
      {
        text: 'Daejeon',
        value: 'Daejeon',
      },
    ],
  },
  {
    text: 'Mexico',
    value: 'Mexico',
    regions: [
      {
        text: 'Centre',
        value: 'Centre',
      },
      {
        text: 'North',
        value: 'North',
      },
      {
        text: 'Gulf',
        value: 'Gulf',
      },
      {
        text: 'Pacific',
        value: 'Pacific',
      },
      {
        text: 'South',
        value: 'South',
      },
    ],
  },
  {
    text: 'Netherlands',
    value: 'Netherlands',
    regions: [
      {
        text: 'North',
        value: 'North',
      },
      {
        text: 'East',
        value: 'East',
      },
      {
        text: 'West',
        value: 'West',
      },
      {
        text: 'South',
        value: 'South',
      },
    ],
  },
  {
    text: 'Nigeria',
    value: 'Nigeria',
    regions: [
      {
        text: 'North Central',
        value: 'North Central',
      },
      {
        text: 'North East',
        value: 'North East',
      },
      {
        text: 'North West',
        value: 'North West',
      },
      {
        text: 'South East',
        value: 'South East',
      },
      {
        text: 'South South',
        value: 'South South',
      },
      {
        text: 'South West',
        value: 'South West',
      },
    ],
  },
  {
    text: 'Poland',
    value: 'Poland',
    regions: [
      {
        text: 'Central Poland',
        value: 'Central Poland',
      },
      {
        text: 'Southern Poland',
        value: 'Southern Poland',
      },
      {
        text: 'Northern Poland',
        value: 'Northern Poland',
      },
      {
        text: 'Western Poland',
        value: 'Western Poland',
      },
      {
        text: 'Eastern Poland',
        value: 'Eastern Poland',
      },
    ],
  },
  {
    text: 'Russia',
    value: 'Russia',
    regions: [
      {
        text: 'Central Federal District',
        value: 'Central Federal District',
      },
      {
        text: 'Northwestern Federal District',
        value: 'Northwestern Federal District',
      },
      {
        text: 'Southern Federal District (from 2010)',
        value: 'Southern Federal District (from 2010)',
      },
      {
        text: 'North Caucasian Federal District',
        value: 'North Caucasian Federal District',
      },
      {
        text: 'Volga Federal District',
        value: 'Volga Federal District',
      },
      {
        text: 'Ural Federal District',
        value: 'Ural Federal District',
      },
      {
        text: 'Siberian Federal District',
        value: 'Siberian Federal District',
      },
    ],
  },
  {
    text: 'SouthAfrica',
    value: 'SouthAfrica',
    regions: [
      {
        text: 'Gauteng',
        value: 'Gauteng',
      },
      {
        text: 'KwaZulu-Natal',
        value: 'KwaZulu-Natal',
      },
      {
        text: 'Western Cape',
        value: 'Western Cape',
      },
      {
        text: 'Northern Cape',
        value: 'Northern Cape',
      },
      {
        text: 'Eastern Cape',
        value: 'Eastern Cape',
      },
      {
        text: 'Limpopo',
        value: 'Limpopo',
      },
      {
        text: 'Mpumalanga',
        value: 'Mpumalanga',
      },
      {
        text: 'North West',
        value: 'North West',
      },
      {
        text: 'Free State',
        value: 'Free State',
      },
    ],
  },
  {
    text: 'Spain',
    value: 'Spain',
    regions: [
      {
        text: 'Navarra',
        value: 'Navarra',
      },
      {
        text: 'Aragón',
        value: 'Arag&#243;n',
      },
      {
        text: 'Cataluña',
        value: 'Catalu&#241;a',
      },
      {
        text: 'Baleares, Islas',
        value: 'Baleares, Islas',
      },
      {
        text: 'Com. Valenciana',
        value: 'Com. Valenciana',
      },
      {
        text: 'Reg.Murcia',
        value: 'Reg.Murcia',
      },
      {
        text: 'Andalucía',
        value: 'Andaluc&#237;a',
      },
      {
        text: 'Canarias, Islas',
        value: 'Canarias, Islas',
      },
      {
        text: 'Ceuta',
        value: 'Ceuta',
      },
      {
        text: 'Extemadura',
        value: 'Extemadura',
      },
      {
        text: 'Melilla',
        value: 'Melilla',
      },
      {
        text: 'Com. Madrid',
        value: 'Com. Madrid',
      },
      {
        text: 'Cantabria',
        value: 'Cantabria',
      },
      {
        text: 'Asturias',
        value: 'Asturias',
      },
      {
        text: 'Rioja, La',
        value: 'Rioja, La',
      },
      {
        text: 'Euskadi',
        value: 'Euskadi',
      },
      {
        text: 'Galicia',
        value: 'Galicia',
      },
      {
        text: 'Castilla La Mancha',
        value: 'Castilla La Mancha',
      },
    ],
  },
  {
    text: 'Turkey',
    value: 'Turkey',
    regions: [
      {
        text: 'Marmara',
        value: 'Marmara',
      },
      {
        text: 'Aegean',
        value: 'Aegean',
      },
      {
        text: 'Central Anatolia',
        value: 'Central Anatolia',
      },
      {
        text: 'Black Sea',
        value: 'Black Sea',
      },
      {
        text: 'Mediterranean',
        value: 'Mediterranean',
      },
      {
        text: 'Eastern Anatolia',
        value: 'Eastern Anatolia',
      },
      {
        text: 'Southeastern Anatolia',
        value: 'Southeastern Anatolia',
      },
    ],
  },
  {
    text: 'UK',
    value: 'UK',
    regions: [
      {
        text: 'SOUTH (London, South East, South West)',
        value: 'SOUTH (London, South East, South West)',
      },
      {
        text: 'NORTH (North West, North East, Yorkshire)',
        value: 'NORTH (North West, North East, Yorkshire)',
      },
      {
        text: 'MID (East of England, West Midlands, East Midlands)',
        value: 'MID (East of England, West Midlands, East Midlands)',
      },
      {
        text: 'REST OF UK (Scotland, Wales, Northern Ireland)',
        value: 'REST OF UK (Scotland, Wales, Northern Ireland)',
      },
    ],
  },
  {
    text: 'US',
    value: 'US',
    regions: [
      {
        text: 'North-east',
        value: 'North-east',
      },
      {
        text: 'South',
        value: 'South',
      },
      {
        text: 'Mid-west',
        value: 'Mid-west',
      },
      {
        text: 'West',
        value: 'West',
      },
    ],
  },
  {
    text: 'Sweden',
    value: 'Sweden',
    regions: [
      {
        text: 'Blekinge',
        value: 'Blekinge',
      },
      {
        text: 'Skåne',
        value: 'Sk&#229;ne',
      },
      {
        text: 'Dalarna',
        value: 'Dalarna',
      },
      {
        text: 'Gävleborg',
        value: 'G&#228;vleborg',
      },
      {
        text: 'Värmland',
        value: 'V&#228;rmland',
      },
      {
        text: 'Örebro',
        value: '&#214;rebro',
      },
      {
        text: 'Gotland',
        value: 'Gotland',
      },
      {
        text: 'Södermanland',
        value: 'S&#246;dermanland',
      },
      {
        text: 'Stockholm',
        value: 'Stockholm',
      },
      {
        text: 'Uppsala',
        value: 'Uppsala',
      },
      {
        text: 'Västmanland',
        value: 'V&#228;stmanland',
      },
      {
        text: 'Halland',
        value: 'Halland',
      },
      {
        text: 'Västra Götaland',
        value: 'V&#228;stra G&#246;taland',
      },
      {
        text: 'Jämtland',
        value: 'J&#228;mtland',
      },
      {
        text: 'Norrbotten',
        value: 'Norrbotten',
      },
      {
        text: 'Västerbotten',
        value: 'V&#228;sterbotten',
      },
      {
        text: 'Västernorrland',
        value: 'V&#228;sternorrland',
      },
      {
        text: 'Jönköping',
        value: 'J&#246;nk&#246;ping',
      },
      {
        text: 'Kalmar',
        value: 'Kalmar',
      },
      {
        text: 'Kronoberg',
        value: 'Kronoberg',
      },
      {
        text: 'Östergötland',
        value: '&#214;sterg&#246;tland',
      },
    ],
  },
];

// baseData.forEach(base => {
//   base.regions.forEach(region => {
//     region.query0 = `REGION: ${base.value}`,
//   })
// })

const continents = {
  Americas: ['Argentina', 'Brazil', 'Canada', 'Mexico', 'US'].sort(),
  'Asia, Middle East, Australasia': [
    'China',
    'Indonesia',
    'Japan',
    'Korea',
    'Russia',
    'Turkey',
    'India',
  ].sort(),
  Europe: [
    'France',
    'Italy',
    'Poland',
    'UK',
    'Netherlands',
    'Spain',
    'Sweden',
    'Germany',
  ].sort(),
  Africa: ['Nigeria', 'South Africa'].sort(),
};

const parsedContinents = Object.keys(continents).map(name => {
  const includedCountries = continents[name];
  return {
    text: name,
    countries: baseData.filter(country => {
      return includedCountries.indexOf(country.text) > -1;
    }),
  };
});

export default parsedContinents;
