/**
 * API utility functions.
 */
import { API } from 'aws-amplify';
import config from '@/config/config.json';
import { get as axiosGet } from 'axios';
import store from '@/store';

/**
 * Make a(n unauthorised) GET request using fetch.
 * @param {String} path
 */
export async function unauthGet(path) {
  return axiosGet(`${config.apiRoot}${path}`);
}

/**
 * Make a GET request using Amplify API.
 * @param {String} path
 */
export async function apiGet(path) {
  const headers = {
    'x-fubi-session-id': store.state.sessionId,
  };

  const resp = await API.get('API', path, { headers, response: true });
  if (resp.headers && resp.headers['x-fubi-new-session-id']) {
    const newSessionId = resp.headers['x-fubi-new-session-id'];
    store.commit('setSessionId', newSessionId);
  }

  return resp.data;
}

/**
 * Make a POST request using Amplify API.
 * @param {String} path   URL to post to.
 * @param {Object} data   Data to send.
 */
export async function apiPost(path, data) {
  const body = data;
  const headers = {
    'Content-Type': 'application/json',
    // 'x-csrf-token': Cookies.get('csrf') || '',
    'x-fubi-session-id': store.state.sessionId,
  };

  const resp = await API.post('API', path, {
    body,
    headers,
    response: true,
  });

  if (resp.headers && resp.headers['x-fubi-new-session-id']) {
    const newSessionId = resp.headers['x-fubi-new-session-id'];
    store.commit('setSessionId', newSessionId);
  }

  return resp.data;
}

/**
 * Make a PUT request using Amplify API.
 * @param {String} path   URL to post to.
 * @param {Object} data   Data to send.
 */
export async function apiPut(path, data) {
  const body = data;
  const headers = {
    'Content-Type': 'application/json',
    // 'x-csrf-token': Cookies.get('csrf') || '',
    'x-fubi-session-id': store.state.sessionId,
  };
  const resp = await API.put('API', path, {
    body,
    headers,
    response: true,
  });

  if (resp.headers && resp.headers['x-fubi-new-session-id']) {
    const newSessionId = resp.headers['x-fubi-new-session-id'];
    store.commit('setSessionId', newSessionId);
  }

  return resp.data;
}

/**
 * Make a PATCH request using Amplify API.
 * @param {String} path   URL to post to.
 * @param {Object} data   Data to send.
 */
export async function apiPatch(path, data) {
  const body = data;
  const headers = {
    'Content-Type': 'application/json',
    // 'x-csrf-token': Cookies.get('csrf') || '',
    'x-fubi-session-id': store.state.sessionId,
  };
  const resp = await API.patch('API', path, {
    body,
    headers,
    response: true,
  });

  if (resp.headers && resp.headers['x-fubi-new-session-id']) {
    const newSessionId = resp.headers['x-fubi-new-session-id'];
    store.commit('setSessionId', newSessionId);
  }

  return resp.data;
}

/**
 * Make a DELETE request using Amplify API.
 * @param {String} path
 */
export async function apiDelete(path) {
  const headers = {
    'x-fubi-session-id': store.state.sessionId,
  };
  const resp = await API.del('API', path, {
    headers,
    response: true,
  });

  if (resp.headers && resp.headers['x-fubi-new-session-id']) {
    const newSessionId = resp.headers['x-fubi-new-session-id'];
    store.commit('setSessionId', newSessionId);
  }

  return resp.data;
}
