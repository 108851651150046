/**
 * User dashboard.
 */
import { apiGet, apiPost } from '@/util/api';

import Fubify from '@/components/Fubifyer/Fubify.vue';
import Wheel from '@/components/Wheel/Wheel.vue';
import GlossaryCard from '@/components/GlossaryCard.vue';

export default {
  name: 'Dashboard',
  components: {
    Wheel,
    Fubify,
    GlossaryCard,
  },
  data: () => ({
    surveys: null,
    countries: null,
    series: null,
    custom: false,
    ready: false,
    signedUrls: {},

    preloadSeriesId: undefined,
    wizardSeries: undefined,

    showCard: null,
    selectedCard: null,

    audienceSize: undefined,
    histogram: undefined,

    mouse: { pageX: 0, pageY: 0 },
    ref: null,
  }),
  beforeCreate() {
    this.$store.commit('setLoading', 'wheel');
  },
  async created() {
    await Promise.all([this.getSurveys(), this.getSeries()]);
    await this.getCountries();
    this.ready = true;
  },
  computed: {
    sCards() {
      const cards = [];
      const glossary = this.$t('glossary');
      Object.keys(glossary).forEach(c => {
        Object.keys(glossary[c]).forEach(g =>
          cards.push({ category: c, genre: g, ...glossary[c][g] }),
        );
      });
      return cards;
    },
  },
  methods: {
    /**
     * Get all the surveys
     */
    async getSurveys() {
      try {
        this.surveys = await apiGet('/survey');
      } catch {
        this.$bvToast.toast(this.$t('dashboard_surveys_get_error'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      }
    },
    async getCountries() {
      try {
        const data = await apiGet(`/survey/${this.surveys[0].id}/countries`);
        this.countries = data.countries;
        this.signedUrls = data.signedUrls;
      } catch {
        this.$bvToast.toast(this.$t('dashboard_countries_get_error'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      }
    },
    /**
     * Get the specified survey.
     * @param survey
     */
    async getSurvey(survey) {
      try {
        const i = this.surveys.findIndex(s => s.id === survey.id);
        this.surveys[i] = await apiGet(`/survey/${survey.id}`);
      } catch {
        this.$bvToast.toast(
          this.$t('dashboard_survey_get_error', [survey.name]),
          {
            title: this.$t('common_error_title'),
            variant: 'danger',
          },
        );
      }
    },
    /**
     * Get the list of saved series.
     */
    async getSeries() {
      try {
        const seriesResponse = await apiGet('/series');
        if (seriesResponse.custom) {
            this.custom = true;
            this.series =  seriesResponse.prompts;
        } else {
            this.series = seriesResponse;
        }
        
      } catch {
        this.$bvToast.toast(this.$t('dashboard_series_get_error'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      }
    },

    /**
     * Save a series from the Wheel.
     */
    async saveSeries(series) {
      try {
        await apiPost('/series', {
          name: series.name,
          series: series.series,
        });
        await this.getSeries();
        this.$bvToast.toast(
          this.$t('dashboard_series_save_success', [series.name]),
          {
            title: this.$t('common_success_title'),
            variant: 'success',
          },
        );
        if (series.recommend)
          this.$router.push({ name: 'recommended', params: series.recommend });
      } catch {
        this.$bvToast.toast(
          this.$t('dashboard_series_save_error', [series.name]),
          {
            title: this.$t('common_error_title'),
            variant: 'danger',
          },
        );
      }
    },
    async navigate({ category, genre }) {
      if (category === 'beauty') category = 'beautiful';
      await this.$router.push({
        name: 'inspiration',
        query: { category, genre },
      });
    },
    async onMouseOver(info) {
      if (info.label) {
        const category = (() => {
          switch (info.klass) {
            case 'beauty':
              return 'Beautiful';
            case 'funny':
              return 'Funny';
            case 'useful':
              return 'Useful';
            case 'inspiring':
              return 'Inspiring';
          }
        })();

        this.selectedCard = {
          category,
          genre: info.label,
          ...this.$t('glossary')[category][info.label],
          showPicture: false,
        };
        this.showCard = true;
      }
    },
    onMouseOut() {
      this.showCard = false;
      this.selectedCard = null; //{ category: '', genre: '' };
    },
    handleRecommendation(params) {
      this.$router.push({ name: 'recommended', params });
    },
    onUpdateSeries(series) {
      this.series = series;
    },
    async onFubify(seriesId) {
      this.preloadSeriesId = null;
      if (typeof seriesId === 'number' && seriesId > -1) {
        await this.$nextTick();
      }
      this.preloadSeriesId = seriesId;
    },
    async onWizardQueryChange(query) {
      this.preloadSeriesId = -1;
      this.wizardSeries = [query];
    },
    onWheelStatsUpdated({ audience, histogram }) {
      if (this.preloadSeriesId > -1) return;
      this.audienceSize = audience;
      this.histogram = histogram;
      this.preloadSeriesId = null;
      this.wizardSeries = undefined;
    },
    onSavedSeries(series) {
      this.series[series.audienceId] = series;
    },
  },
};
