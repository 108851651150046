/**
 * Main app.
 */
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import SideMenu from '@/components/SideMenu.vue';

export default {
  name: 'app',
  components: {
    Footer,
    Header,
    SideMenu,
  },
  computed: {
    /**
     * Determine whether or not to show the header.
     * If this becomes to unwieldy, control using route metadata instead.
     */
    showHeader() {
      return (
        this.$store.state.user.userId || this.$route.name === 'forgotpassword'
      );
    },
    showFooter() {
      return this.$route.name !== 'frontpage';
    },
  },
};
