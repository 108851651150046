import continents from './locations';
import attitudes from './attitudes';
import interests from './interests';

export default {
  continents,
  attitudes,
  demographics: {
    gender: [
      { text: 'Male', value: 'MALE' },
      { text: 'Female', value: 'FEMALE' },
    ],
    education: [
      { text: 'Elementary school', value: 'ELEMENTARY SCHOOL' },
      { text: 'Senior high school', value: 'SENIOR HIGH SCHOOL' },
      { text: 'College', value: 'JUNIOR COLLEGE/TRADE SCHOOL' },
      {
        text: 'Graduate degree',
        value: 'College/University (Non-science degree)',
      },
      {
        text: 'Postgraduate degree',
        value: 'Graduate school (Non-science degree)',
      },
    ],
    marital: [
      { text: 'Single', value: 'SINGLE' },
      {
        text: 'Married or living as married',
        value: 'Married/ living as married',
      },
      {
        text: 'Divorced, widowed, or separated',
        value: 'Divorced/ separated/ widowed',
      },
    ],
    homeOwnership: [
      { text: 'Own outright', value: 'Owned outright' },
      {
        text: 'Own with a mortgage or loan',
        value: 'Owned with a mortgage or loan',
      },
      { text: 'Shared ownership', value: 'Shared ownership' },
      { text: 'Social rented', value: 'Social rented' },
      { text: 'Privately rented', value: 'Privately rented' },
      {
        text: 'Other (e.g. rent free)',
        value: 'Other (including living rent free)',
      },
    ],
    urban: [
      { text: 'Urban', value: 'URBAN' },
      { text: 'Suburban', value: 'SUBURBAN' },
      { text: 'Rural', value: 'RURAL' },
    ],
    ethnicity: [
      { text: 'Caucasian', value: 'Caucasian' },
      { text: 'African American', value: 'AFRICAN AMERICAN' },
      { text: 'Hispanic or Latino', value: 'Hispanic or Latino' },
      { text: 'Asian', value: 'Asian' },
      { text: 'Other', value: 'Other' },
    ],
  },
  consumer: { interests },
};
