export const icons = {
  interface: {
    clear: require('./interface/clear.svg'),
    delete: require('./interface/delete.svg'),
    edit: require('./interface/edit.svg'),
    help: require('./interface/help.svg'),
    locked: require('./interface/locked.svg'),
    tacometer: require('./interface/tacometer.svg'),
    unlocked: require('./interface/unlocked.svg'),
    location: require('./interface/location.svg'),
    add: require('./interface/add.svg'),
    insights: require('./interface/insights.svg'),
    publishedhome: require('./interface/homePublished.svg'),
    unpublishedhome: require('./interface/homeUnpublished.svg'),
    refresh: require('./interface/refresh.svg'),
    bookmarkoutline: require('./interface/bookmarkOutline.svg'),
    bookmarkadd: require('./interface/bookmarkAdd.svg'),
    bookmarkfill: require('./interface/bookmarkFill.svg'),
    bookmarkcheck: require('./interface/bookmarkCheck.svg'),
    'sentiment++': require('./interface/sentiment++.svg'),
    'sentiment+': require('./interface/sentiment+.svg'),
    'sentiment-': require('./interface/sentiment-.svg'),
    'sentiment--': require('./interface/sentiment--.svg'),
    'sentiment~': require('./interface/sentiment~.svg'),
    thumbsup: require('./interface/thumbsUp.svg'),
  },
  funny: {
    dark: require('./funny/dark.svg'),
    deadpan: require('./funny/deadpan.svg'),
    highbrow: require('./funny/highbrow.svg'),
    parody: require('./funny/parody.svg'),
    satire: require('./funny/satire.svg'),
    'self-deprecating': require('./funny/self-deprecating.svg'),
    slapstick: require('./funny/slapstick.svg'),
    surreal: require('./funny/surreal.svg'),
    potty: require('./funny/toilet.svg'),
    outrageous: require('./funny/transgressive.svg'),
    transgressive: require('./funny/transgressive.svg'),
    zany: require('./funny/zany.svg'),
  },
  useful: {
    'authoritative opinion': require('./useful/authoritativeopinion.svg'),
    'cultural curation': require('./useful/culturalcuration.svg'),
    hotlists: require('./useful/hotlists.svg'),
    'how to guide': require('./useful/howto.svg'),
    'handy tips': require('./useful/howto.svg'),
    'news analysis': require('./useful/newsanalysis.svg'),
    'news updates': require('./useful/newsupdates.svg'),
    'consumer reviews': require('./useful/reviews.svg'),
    'search/answer': require('./useful/searchanswer.svg'),
    'social good': require('./useful/socialgood.svg'),
    trivia: require('./useful/trivia.svg'),
  },
  beautiful: {
    cute: require('./beautiful/cute.svg'),
    ethereal: require('./beautiful/ethereal.svg'),
    extravagant: require('./beautiful/extravagant.svg'),
    intricate: require('./beautiful/intricate.svg'),
    minimalist: require('./beautiful/minimalist.svg'),
    pop: require('./beautiful/pop.svg'),
    sensual: require('./beautiful/sensual.svg'),
    spontaneous: require('./beautiful/spontaneous.svg'),
    'stylised/abstract': require('./beautiful/stylised.svg'),
  },
  inspiring: {
    biographical: require('./inspiring/biography.svg'),
    enraging: require('./inspiring/enraging.svg'),
    'big ideas': require('./inspiring/grandiose.svg'),
    grandiose: require('./inspiring/grandiose.svg'),
    heartwarming: require('./inspiring/heartwarming.svg'),
    ideas: require('./inspiring/ideas.svg'),
    'life-coaching': require('./inspiring/lifecoaching.svg'),
    spiritual: require('./inspiring/spiritual.svg'),
    thrilling: require('./inspiring/thrilling.svg'),
    weird: require('./inspiring/weird.svg'),
    wonder: require('./inspiring/wonder.svg'),
    'social good': require('./useful/socialgood.svg'),
  },
};
