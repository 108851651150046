/**
 * Common side menu.
 */
import Icon from '@/icons/Icon.vue';

export default {
  name: 'SideMenu',
  components: {
    Icon,
  },
};
