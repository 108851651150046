/**
 * Common page footer.
 */
import Icon from '@/icons/Icon.vue';
export default {
  name: 'GlossaryCard',
  components: {
    Icon,
  },
  props: {
    popup: { type: Boolean, default: false },
    category: String,
    genre: String,
    description: String,
    youKnow: String,
  },
  computed: {
    knowPreamble() {
      return this.$t('glossary_you_know_preamble').split('<!!>');
    },
    imageStyle() {
      return {
        backgroundImage: `url(/img/toptrumps/${this.category.toLowerCase()}/${this.genre
          .toLowerCase()
          .replace(/\W/g, '')}.jpg)`,
      };
    },
  },
};
