/**
 * Login page.
 */
import { Auth } from 'aws-amplify';
import { unauthGet, apiPost } from '../util/api';
import Icon from '@/icons/Icon.vue';
import { random } from 'lodash';

export default {
  name: 'Login',
  components: {
    Icon,
  },
  data() {
    return {
      loadingContent: true,
      username: '',
      usernameState: null,
      password: '',
      passwordState: null,
      loginFailed: false,
      errorMessage: '',
      formValid: false,
      saving: false,
      randomContent: null,
    };
  },
  created: async function() {
    try {
      const contentCount = (await unauthGet('/auth/random-content/count/all'))
        .data;
      if (contentCount > 0)
        await unauthGet(
          `/auth/random-content/content/${random(contentCount - 1)}`,
        ).then(resp => this.setContent(resp.data));
    } finally {
      this.loadingContent = false;
    }
  },
  computed: {
    thumbnailStyle: function() {
      return {
        backgroundImage: `url(${this.randomContent.thumbnail})`,
      };
    },
    backdropStyle: function() {
      return {
        backgroundImage: `url(${this.randomContent.backdrop})`,
      };
    },
  },
  methods: {
    /**
     * Check whether the login form is valid or not.
     * @param {String} ref
     */
    checkFormValid(ref) {
      if (this.loginFailed) {
        this.usernameState = null;
        this.passwordState = null;
        this.loginFailed = false;
      }
      this[`${ref}State`] = this.$refs[ref].checkValidity();
      this.formValid =
        this.$refs['username'] && this.$refs['password'].checkValidity();
    },

    /**
     * Attempt to log the user in with the provided credentials.
     */
    async login() {
      this.saving = true;
      let signedIn = false;

      // Attempt to sign in via Amplify
      await Auth.signIn(this.username, this.password)
        .then(() => {
          signedIn = true;
        })
        .catch(error => {
          if (error.message) {
            this.errorMessage = error.message;
          }
        });

      // Use first API request to check whether we are authorised to proceed
      if (signedIn) {
        await apiPost('/log').catch(error => {
          if (error.response && error.response.status === 401) {
            this.errorMessage = this.$t('login_error_client_locked');
          } else {
            this.errorMessage = this.$t('login_error_client_generic');
          }
          signedIn = false;
        });
      }

      // Tidy up
      if (signedIn) {
        this.$router.push({ name: 'dashboard' }).catch(() => {});
      } else {
        Auth.signOut(); // Sign user out of Amplify if not signed in (needed if client / user is locked).
        this.loginFailed = true;
        this.usernameState = false;
        this.passwordState = false;
      }

      this.saving = false;
    },

    /**
     * Set content
     */
    async setContent(content) {
      if (!content || !content.name) return;
      this.loadingContent = false;
      const randomContent = {
        name: content.name,
        description: this.strTruncate(content.description, 250, true),
        copyright: content.copyright,
        thumbnail: content.thumbnail,
        backdrop: content.backdrop,
        location: (() => {
          const country =
            content.tags &&
            content.tags.find(tag => tag.category === 'COUNTRY');
          return {
            country,
            region:
              country &&
              content.tags.find(
                tag =>
                  tag.parentTag &&
                  tag.parentTag.id === country.id &&
                  tag.category === 'REGION',
              ),
          };
        })(),
        genres:
          content.tags && content.tags.filter(tag => tag.category === 'GENRE'),
      };

      // Ensure we have a maximum of three genres
      if (randomContent.genres.length > 3) {
        const selectedGenres = [];
        const rIndices = [];
        for (let i = 0; i < 3; i++) {
          let rIndex = 0;
          do {
            rIndex = Math.floor(
              Math.random() * (randomContent.genres.length - 1),
            );
          } while (rIndices.includes(rIndex));
          rIndices.push(rIndex);
          selectedGenres.push(randomContent.genres[rIndex]);
        }
        randomContent.genres = selectedGenres;
      }

      this.randomContent = randomContent;
    },

    /**
     * Truncate a string to the specified length.
     * Adds ellipsis.
     *
     * @param {String} string
     * @param {Number} maxLength
     */
    strTruncate(string, maxLength, ellipsis) {
      if (string.length > maxLength) {
        string = string.substring(0, maxLength);
        if (ellipsis) {
          string += ' ...';
        }
      }
      return string;
    },
  },
};
