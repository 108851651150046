import Slider from 'vue-slider-component';
import Wheel from '../Wheel/Wheel.vue';
import DATA from './WizardData';

export default {
  name: 'Wizard',
  components: {
    Slider,
    Wheel,
  },
  props: {
    countries: Array,
    audienceSize: Object,
    replacingSeries: Object,
  },
  data: () => ({
    step: 'name',
    loading: true,
    processing: false,
    form: {
      name: undefined,
      attitudes: {
        agree: [],
        disagree: [],
      },
      location: {
        countries: [],
        regions: [],
      },
      demographic: {
        gender: [],
        age: [],
        education: [],
        marital: [],
        homeOwnership: [],
        urban: [],
        ethnicity: [],
      },
      consumer: {
        interests: [],
      },
    },
  }),
  watch: {
    query() {
      if (!this.loading) {
        this.emitQuery();
      }
    },
    audienceSize() {
      this.processing = false;
    },
  },
  computed: {
    isLastStep() {
      return this.step === 'interests';
    },
    query() {
      const query = [];
      const form = this.form;

      // It's a bit annoying, but because of the way the wheel works it's easier
      // to force the query to be a string of ANDs even if that means translating
      // "what the user has selected" into "not what the user has not selected"
      // It also keeps the query from getting too long if they select a lot of things

      // LOCATIONS
      const location = form.location;
      DATA.continents.forEach(continent => {
        continent.countries.forEach(country => {
          const allowedCountry = this.countries.find(
            c => c.name === country.text,
          );

          if (!allowedCountry) return;

          if (!location.countries.includes(country.value)) {
            query.push(['COUNTRY', '!=', country.value, 'AND']);
          }

          country.regions.forEach(region => {
            const cacheName = `${country.value} # ${region.value}`;
            if (!location.regions.includes(cacheName)) {
              query.push([
                `REGION: ${country.value}`,
                '!=',
                region.value,
                'AND',
              ]);
            }
          });
        });
      });

      // DEMOGRAPHICS
      const demographic = form.demographic;

      if (demographic.gender.length === 1) {
        query.push(['GENDER', '==', demographic.gender[0], 'AND']);
      }

      if (this.form.demographic.education) {
        query.push([
          'HIGHEST LEVEL OF EDUCATION',
          '>=',
          this.form.demographic.education[0],
          'AND',
        ]);
        query.push([
          'HIGHEST LEVEL OF EDUCATION',
          '<=',
          this.form.demographic.education[1],
          'AND',
        ]);
      }
      if (demographic.age.length === 2) {
        query.push(
          ['AGE', '>=', demographic.age[0].toString(), 'AND'],
          ['AGE', '<=', demographic.age[1].toString(), 'AND'],
        );
      }

      DATA.demographics.marital.forEach(marry => {
        if (!demographic.marital.includes(marry.value)) {
          query.push(['MARITAL STATUS', '!=', marry.value, 'AND']);
        }
      });

      DATA.demographics.homeOwnership.forEach(homeOwnership => {
        if (!demographic.homeOwnership.includes(homeOwnership.value)) {
          query.push(['RENT OR BUY', '!=', homeOwnership.value, 'AND']);
        }
      });

      DATA.demographics.urban.forEach(urban => {
        if (!demographic.urban.includes(urban.value)) {
          query.push(['URBAN/RURAL/SUBURBAN', '!=', urban.value, 'AND']);
        }
      });

      if (this.selectedUSOnly) {
        DATA.demographics.ethnicity.forEach(ethnicity => {
          if (!demographic.ethnicity.includes(ethnicity.value)) {
            query.push(['US ONLY: ETHNICITY', '!=', ethnicity.value, 'AND']);
          }
        });
      }

      form.attitudes.agree.forEach(att => {
        query.push([att, '>=', 'AGREE SOMEWHAT', 'AND']);
      });

      form.attitudes.disagree.forEach(att => {
        query.push([att, '<=', 'DISAGREE SOMEWHAT', 'AND']);
      });

      form.consumer.interests.forEach(interest => {
        query.push([interest, '==', 'SELECTED', 'AND']);
      });

      return {
        name: 'ORD-481354-H9W1_final_2020-02-26_final',
        normalize: true,
        scale: 2.5,
        query,
        summary: this.summary,
      };
    },
    summary() {
      try {
        let summary = '';

        if (this.form.demographic.gender.length === 2)
          summary = 'Men and women';
        else if (this.form.demographic.gender[0] === 'MALE') summary = 'Men';
        else if (this.form.demographic.gender[0] === 'FEMALE')
          summary = 'Women';
        else summary = 'People';

        summary += ' from ' + this.form.location.countries.join(', ');

        if (this.form.demographic.age.length === 2) {
          summary += `, and are between ${this.form.demographic.age[0]} and ${this.form.demographic.age[1]} years old.`;
        }

        if (this.form.demographic.education.length === 2) {
          summary +=
            '\n\nTheir level of education is between ' +
            this.form.demographic.education[0]
              .replace(' (Non-science degree)', '')
              .toLowerCase() +
            ' and ' +
            this.form.demographic.education[1]
              .replace(' (Non-science degree)', '')
              .toLowerCase() +
            '.';
        }

        summary +=
          '\n\nThey are ' +
          this.form.demographic.marital
            .join(', ')
            .replace(/\/\s/g, ', ')
            .toLowerCase() +
          '.';
        summary +=
          '\n\nTheir home is ' +
          this.form.demographic.homeOwnership.join(', ').toLowerCase() +
          '.';
        summary +=
          '\n\nTheir area is ' +
          this.form.demographic.urban.join(', ').toLowerCase() +
          '.';

        if (this.selectedUSOnly) {
          summary +=
            '\n\nThey are ' +
            this.form.demographic.ethnicity.join(', ').toLowerCase() +
            '.';
        }

        if (
          this.form.attitudes.agree.length > 0 ||
          this.form.attitudes.disagree.length > 0
        ) {
          summary += '\n\nAttitudes: ';
          summary += [
            ...this.form.attitudes.agree,
            ...this.form.attitudes.disagree,
          ]
            .join(', ')
            .toLowerCase();
        }

        if (this.form.consumer.interests.length > 0) {
          summary +=
            '\n\nThey are interested in ' +
            this.form.consumer.interests
              .map(int =>
                int
                  .split(':')[1]
                  .trim()
                  .toLowerCase(),
              )
              .join(', ') +
            '.';
        }

        return summary;
      } catch (e) {
        console.error('Unable to generate summary.', e);
        return null;
      }
    },
    countriesByContinentOptions() {
      const continents = [];
      DATA.continents.forEach(continent => {
        const filteredContinent = {
          text: continent.text,
          value: continent.value,
          countries: [],
        };
        continent.countries.forEach(country => {
          const allowedCountry = this.countries.find(
            c => c.name === country.text,
          );
          if (allowedCountry) filteredContinent.countries.push(country);
        });
        if (filteredContinent.countries.length > 0)
          continents.push(filteredContinent);
      });
      return continents;
    },
    regionsByCountryOptions() {
      const countries = [];
      DATA.continents.forEach(continent => {
        continent.countries.forEach(country => {
          if (this.form.location.countries.includes(country.value)) {
            countries.push({
              text: country.text,
              value: country.value,
              regions: country.regions?.map(({ text, value }) => ({
                text,
                value: `${country.value} # ${value}`,
              })),
            });
          }
        });
      });
      return countries;
    },
    selectedUSOnly() {
      return (
        this.form.location.countries.length === 1 &&
        this.form.location.countries[0] === 'US'
      );
    },
    genderOptions() {
      return DATA.demographics.gender.map(gendum => ({
        ...gendum,
        disabled:
          this.form.demographic.gender.length === 1 &&
          this.form.demographic.gender[0] === gendum.value,
      }));
    },
    educationOptions() {
      return DATA.demographics.education;
    },
    maritalOptions() {
      return DATA.demographics.marital.map(marry => ({
        ...marry,
        disabled:
          this.form.demographic.marital.length === 1 &&
          this.form.demographic.marital[0] === marry.value,
      }));
    },
    homeOptions() {
      return DATA.demographics.homeOwnership.map(home => ({
        ...home,
        disabled:
          this.form.demographic.homeOwnership.length === 1 &&
          this.form.demographic.homeOwnership[0] === home.value,
      }));
    },
    urbanOptions() {
      return DATA.demographics.urban.map(urb => ({
        ...urb,
        disabled:
          this.form.demographic.urban.length === 1 &&
          this.form.demographic.urban[0] === urb.value,
      }));
    },
    ethnicityOptions() {
      return DATA.demographics.ethnicity.map(eth => ({
        ...eth,
        disabled:
          this.form.demographic.ethnicity.length === 1 &&
          this.form.demographic.ethnicity[0] === eth.value,
      }));
    },
    totalAttitudesCount() {
      return (
        this.form.attitudes.agree.length + this.form.attitudes.disagree.length
      );
    },
    attitudesAgreeOptions() {
      return DATA.attitudes.map(attitude => ({
        ...attitude,
        disabled:
          (this.totalAttitudesCount >= 3 &&
            !this.form.attitudes.agree.includes(attitude.value)) ||
          this.form.attitudes.disagree.includes(attitude.value),
      }));
    },
    attitudesDisagreeOptions() {
      return DATA.attitudes.map(attitude => ({
        ...attitude,
        disabled:
          (this.totalAttitudesCount >= 3 &&
            !this.form.attitudes.disagree.includes(attitude.value)) ||
          this.form.attitudes.agree.includes(attitude.value),
      }));
    },
    interestsOptions() {
      return DATA.consumer.interests.map(interest => ({
        ...interest,
        disabled:
          this.form.consumer.interests.length >= 3 &&
          !this.form.consumer.interests.includes(interest.value),
      }));
    },
    audienceQualityText() {
      const size = this.audienceSize?.value;
      let text;
      if (size > 75) text = '🟢 Good sample size';
      else if (size > 25) text = '🟡 Marginal sample size';
      else if (size > 0) text = '🔴 Poor sample size';
      else if (size === 0)
        text = '❌ Empty sample. Please broaden your search.';
      else text = 'Unknown';
      return `${text} (${size})`;
    },
    audienceQualityStyle() {
      const size = this.audienceSize?.value;
      if (size > 75) return { color: '#00ac46' }; // Good
      if (size > 25) return { color: '#fd8c00' }; // OK
      if (size > 0) return { color: '	#dc0000' }; // Bad
      if (size === 0) return { color: '#780000' }; //Empty
      return { color: '#000' };
    },
  },
  created() {
    this.setDefaults();
    try {
      if (this.replacingSeries.series) {
        this.loadExistingSeries();
      }
    } catch (e) {
      console.error('Unable to load existing series.');
      console.error(e);
      this.setDefaults();
    }

    this.loading = false;
    this.emitQuery();
  },
  mounted() {
    this.$bvModal.show('wizard-modal');
  },
  methods: {
    setDefaults() {
      const firstEdu = DATA.demographics.education.at(0);
      const lastEdu = DATA.demographics.education.at(-1);
      this.form.demographic.education = [firstEdu.value, lastEdu.value];

      this.form.demographic.age = [0, 100];

      DATA.demographics.gender.forEach(gender => {
        this.form.demographic.gender.push(gender.value);
      });

      DATA.continents.forEach(continent => {
        continent.countries.forEach(country => {
          const allowedCountry = this.countries.find(
            c => c.name === country.text,
          );
          if (allowedCountry) {
            this.form.location.countries.push(country.value);
            country.regions.forEach(region => {
              this.form.location.regions.push(
                `${country.value} # ${region.value}`,
              );
            });
          }
        });
      });

      DATA.demographics.marital.forEach(marital => {
        this.form.demographic.marital.push(marital.value);
      });

      DATA.demographics.homeOwnership.forEach(homeOwnership => {
        this.form.demographic.homeOwnership.push(homeOwnership.value);
      });

      DATA.demographics.urban.forEach(urban => {
        this.form.demographic.urban.push(urban.value);
      });

      DATA.demographics.ethnicity.forEach(ethnicity => {
        this.form.demographic.ethnicity.push(ethnicity.value);
      });
    },
    loadExistingSeries() {
      const { query } = JSON.parse(this.replacingSeries.series);
      this.form.name = this.replacingSeries.name;

      query.forEach(el => {
        if (el[0] === 'COUNTRY') {
          this.form.location.countries = this.form.location.countries.filter(
            c => c !== el[2],
          );
        }

        if (el[0].startsWith('REGION:')) {
          const country = el[0].split(':')[1].trim();
          const region = el[2];
          const regionValue = `${country} # ${region}`;
          this.form.location.regions = this.form.location.regions.filter(
            c => c.toLowerCase() !== regionValue.toLowerCase(),
          );
        } else if (el[0] === 'GENDER') {
          this.form.demographic.gender = [el[2]];
        } else if (el[0] === 'AGE' && el[1] === '>=') {
          this.form.demographic.age[0] = parseInt(el[2]) || 0;
        } else if (el[0] === 'AGE' && el[1] === '<=') {
          this.form.demographic.age[1] = parseInt(el[2]) || 100;
        } else if (el[0] === 'HIGHEST LEVEL OF EDUCATION' && el[1] === '>=') {
          this.form.demographic.education[0] = el[2];
        } else if (el[0] === 'HIGHEST LEVEL OF EDUCATION' && el[1] === '<=') {
          this.form.demographic.education[1] = el[2];
        } else if (el[0] === 'MARITAL STATUS') {
          this.form.demographic.marital = this.form.demographic.marital.filter(
            c => c !== el[2],
          );
        } else if (el[0] === 'RENT OR BUY') {
          this.form.demographic.homeOwnership = this.form.demographic.homeOwnership.filter(
            c => c !== el[2],
          );
        } else if (el[0] === 'URBAN/RURAL/SUBURBAN') {
          this.form.demographic.urban = this.form.demographic.urban.filter(
            c => c !== el[2],
          );
        } else if (el[0] === 'US ONLY: ETHNICITY') {
          this.form.demographic.ethnicity = this.form.demographic.ethnicity.filter(
            c => c !== el[2],
          );
        } else if (el[2] === 'AGREE SOMEWHAT') {
          this.form.attitudes.agree.push(el[0]);
        } else if (el[2] === 'DISAGREE SOMEWHAT') {
          this.form.attitudes.disagree.push(el[0]);
        } else if (el[0].startsWith('ANY:')) {
          this.form.consumer.interests.push(el[0]);
        }

        if (this.form.demographic.gender.length === 0) {
          this.form.demographic.gender = ['MALE', 'FEMALE'];
        }
      });
    },
    progress() {
      if (this.isLastStep) {
        this.$emit('done', this.form.name, JSON.stringify(this.query));
        return;
      }

      if (this.step === 'name') this.step = 'countries';
      else if (this.step === 'countries') this.step = 'regions';
      else if (this.step === 'regions') this.step = 'demographics';
      else if (this.step === 'demographics') this.step = 'attitudes';
      else if (this.step === 'attitudes') this.step = 'interests';
    },
    back() {
      if (this.step === 'countries') this.step = 'name';
      else if (this.step === 'regions') this.step = 'countries';
      else if (this.step === 'demographics') this.step = 'regions';
      else if (this.step === 'attitudes') this.step = 'demographics';
      else if (this.step === 'interests') this.step = 'attitudes';
    },
    emitQuery() {
      this.processing = true;
      this.$emit('queryChange', {
        audienceId: -1,
        name: 'Query',
        series: JSON.stringify(this.query),
      });
    },
    addAllCountriesByContinent(continent) {
      continent.countries.forEach(country => {
        if (!this.form.location.countries.includes(country.value)) {
          this.form.location.countries.push(country.value);
        }
        this.addAllRegionsByCountry(country);
      });
    },
    removeAllCountriesByContinent(continent) {
      continent.countries.forEach(country => {
        this.form.location.countries = this.form.location.countries.filter(
          c => c !== country.value,
        );
      });
    },
    addAllRegionsByCountry(country) {
      country.regions.forEach(region => {
        if (!this.form.location.regions.includes(region.value)) {
          this.form.location.regions.push(region.value);
        }
      });
    },
    removeAllRegionsByCountry(country) {
      country.regions.forEach(region => {
        this.form.location.regions = this.form.location.regions.filter(
          r => r !== region.value,
        );
      });
    },
  },
};
