/**
 * Wheel integration.
 */
export default {
  props: {
    surveys: Array,
    series: Array,
    countries: Array,
    getSurvey: Function,
    signedUrls: Object,
    analyse: false,
    preloadSeriesId: Number,
    ageRestriction: { type: String, default: 'NONE' },
  },
  data: () => ({
    readyToLoad: false,
    loaded: false,
    thinking: false,
    seriesNames: [],
    selectedSeries: null,
    pendingSavedSeriesName: '',
    pendingSavedSeriesJson: '',
    requestError: null,
    nameError: null,
    modalOnOk: () => {},
    recommendedPayload: null,
    showDontSave: false,
  }),
  async created() {
    this.$store.commit('setLoading', 'wheel');
  },
  async mounted() {
    this.seriesNames = this.getSeriesNames(this.series);
    window.paddleAPI = {
      getCountries: () =>
        this.countries.map(c => c.name && c.name.toUpperCase()),
      onrequestsurveylist: () => this.surveys.map(survey => survey.name),
      onloaded: () => {
        this.loaded = true;
        this.$emit('loaded', window.paddleAPI.analysis || {});
      },
      onsaveseries: serialized => this.showSaveSeriesModal(serialized),
      onrequestsurveyfile: this.requestSurveyFile,
      onrequestseries: this.requestSeries,
      onclick: this.click,
      setthinking: state => {
        this.thinking = state;
        if (state) {
          this.$store.commit('setLoading', 'wheel');
        } else {
          setTimeout(() => this.$store.commit('unsetLoading', 'wheel'), 1000);
        }
      },
      getRemovePeopleUnderAlcoholAge: () => this.ageRestriction === 'LDA',
      getRemovePeopleUnderSmokingAge: () => this.ageRestriction === 'U21',
      allowClick: true,
      onmouseover: info => this.$emit('mouseover', info),
      onmouseout: info => this.$emit('mouseout', info),
      preloadSeries:
        typeof this.preloadSeriesId === 'number' &&
        this.series.find(s => s.audienceId === parseInt(this.preloadSeriesId))
          ?.series,
      recommend: serialized => {
        this.showDontSave = true;
        this.recommendedPayload = serialized;
        this.showSaveSeriesModal(serialized);
      },
      sendUpdatedStats: (audience, histogram) => {
        this.$emit('wheelDataUpdate', { audience, histogram });
      },
    };

    if (this.analyse) {
      window.paddleAPI.analyse = this.analyse.series;
    }

    this.readyToLoad = true;
  },
  watch: {
    series: function(newSeries) {
      this.seriesNames = this.getSeriesNames(newSeries);
    },
  },
  methods: {
    async showSaveSeriesModal(serialized) {
      this.pendingSavedSeriesJson = serialized;
      this.$bvModal.show('saveSeriesModal');
    },

    async saveSeries(event) {
      this.pendingSavedSeriesName = this.pendingSavedSeriesName.trim();
      if (!this.pendingSavedSeriesName) {
        event.preventDefault();
        this.nameError = this.$t('queries_page_error_blank_name');
        return;
      } else {
        this.nameError = '';
      }

      const series = {
        name: this.pendingSavedSeriesName,
        series: this.pendingSavedSeriesJson,
      };

      if (this.recommendedPayload) {
        series.series = series.series.query; // wow
        series.recommend = this.recommendedPayload;
      }

      this.$emit('save-series', series);
      this.$bvModal.hide('saveSeriesModal');
    },

    closeSaveModal() {
      this.pendingSavedSeriesName = '';
      this.pendingSavedSeriesJson = '';
      this.nameError = null;
      this.recommendedPayload = null;
      this.showDontSave = false;
    },

    async requestSurveyFile(name) {
      const fileType = [
        '_synonyms.json',
        '_data.txt',
        '.xml',
        '_weights.csv',
        '_allocations.csv',
      ].find(
        suffix => name !== (name = name.replace(new RegExp(`${suffix}$`), '')),
      );
      const surveyName = name;
      const countries = this.countries.map(c => c.name);

      if (fileType === '_allocations.csv') {
        const file = await fetch(this.signedUrls[`${surveyName}${fileType}`]);
        if (file.ok) return file;
        else throw new Error(name);
      } else if (fileType === '_data.txt') {
        const data_txts = await Promise.all(
          countries.map(country =>
            fetch(this.signedUrls[`${surveyName}_${country}${fileType}`]),
          ),
        );

        let allData = [];
        for (let file of data_txts) {
          const fileBuffer = await file.arrayBuffer();
          if (file.ok) allData = allData.concat(fileBuffer);
          else throw new Error(name);
        }

        return new Promise((res, rej) => {
          var reader = new FileReader();
          reader.onload = function() {
            res({ text: () => reader.result });
          };
          reader.onerror = function() {
            rej(name);
          };
          reader.readAsText(new Blob(allData));
        });
      } else {
        const file = await fetch(
          this.signedUrls[`${surveyName}_${countries[0]}${fileType}`],
        );
        if (file.ok) return file;
        else throw new Error(name);
      }
    },

    async requestSeries() {
      return new Promise(resolve => {
        this.$bvModal.show('requestSeriesModal');
        this.modalOnOk = async event => {
          event.preventDefault();
          if (this.selectedSeries) {
            await resolve(this.selectedSeries);
            this.$bvModal.hide('requestSeriesModal');
          } else {
            this.requestError = this.$t('wheel_modal_load_series_error_blank');
          }
        };
      });
    },

    async click(params) {
      if (window.paddleAPI.allowClick) {
        window.paddleAPI.allowClick = false;
        const { klass: category, label: genre } = params;
        this.$emit('navigate', {
          category: category && category.toLowerCase(),
          genre: genre && genre.toLowerCase(),
        });
      }
    },

    closeRequestModal() {
      this.requestError = null;
    },

    getSeriesNames(series) {
      return series.map(s => ({
        text: `${s.name}${
          s.series.indexOf('Manual') > -1
            ? ` (${this.$t('wheel_modal_load_manual_series_nb')})`
            : ''
        }`,
        value: s.series,
      }));
    },

    cancelSave() {
      if (this.showDontSave) {
        this.$router.push({
          name: 'recommended',
          params: this.recommendedPayload,
        });
      }
    },
  },
};
