/**
 * British English language file.
 */
const lang = {
  // COMMON
  common_FUBI: 'FUBI',
  common_survey: 'Survey',
  common_save: 'Save',
  common_load: 'Load',
  common_ok: 'OK',
  common_done: 'Done',
  common_cancel: 'Cancel',
  common_clear: 'Clear',
  common_next: 'Next',
  common_delete: 'Delete',
  common_remove: 'Remove',
  common_back: 'Back',
  common_search: 'Search',
  common_success_title: 'Success',
  common_error_title: 'Oops',
  common_error_body: 'Something went wrong.',
  common_list_params: '{0} to {1} of {2}',
  common_title: 'Title',
  common_name: 'Name',
  common_tag: 'Tag',
  common_tags: 'Tags',
  common_genre: 'Genre',
  common_category: 'Category',
  common_country: 'Country',
  common_region: 'Region',
  common_filter: 'Filter',
  common_filters: 'Filters',
  common_link: 'Link',
  common_file: 'Thumbnail',
  common_description: 'Description',
  common_author: 'Author',
  common_refresh: 'Refresh',

  // Roles (should correspond to ENUM values on user.role in database)
  ADMIN: 'Admin',
  CURATOR: 'Curator',
  SUPERADMIN: 'Superadmin',
  USER: 'User',

  // CLIENTS
  clients_error_delete_client: 'Unable to delete {clientName}.',
  clients_error_get_clients: 'Unable to get clients.',
  clients_error_set_client_lock: 'Unable to lock {clientName}.',
  clients_error_set_client_unlock: 'Unable to unlock {clientName}.',
  clients_header: 'Clients',
  clients_introduction:
    "This shows all of the clients in the system. You may remove clients and set them locked (preventing the \
    client's users from logging in). Click on the client's name to edit its details.",
  clients_list_client_name: 'Client Name',
  clients_modal_confirm_delete_title: 'Delete {clientName}',
  clients_modal_confirm_delete_text:
    'Are you sure you want to permanently delete {clientName}? All of the data associated with the client, including \
    its users, will be deleted.',
  clients_modal_confirm_lock_text:
    "Are you sure you want to lock {clientName}? The client's users will no longer be able to log in.",
  clients_modal_confirm_lock_title: 'Lock {clientName}',
  clients_modal_confirm_unlock_text:
    "Are you sure you want to unlock {clientName}? The client's users will be able to log in.",
  clients_modal_confirm_unlock_title: 'Unlock {clientName}',
  clients_new_client: 'New Client',
  clients_new_client_title: 'Create a new client.',
  clients_no_clients: 'No clients found.',
  clients_success_delete_client: '{clientName} deleted.',
  clients_success_set_client_lock: '{clientName} locked.',
  clients_success_set_client_unlock: '{clientName} unlocked.',
  clients_title_client_delete: 'Delete client',
  clients_title_client_locked: 'Client locked. Click to unlock.',
  clients_title_client_unlocked: 'Client unlocked. Click to lock.',
  contents_success_publish_content: 'Content published',
  contents_success_unpublish_content: 'Content unpublished',
  contents_error_publish_content: 'There was a problem publishing content.',
  contents_error_unpublish_content: 'There was a problem unpublishing content.',

  // VOICE CONFIG
  menu_voices: 'Voices',
  configure_voices_header: 'Voice Configuration',
  configure_voices_introduction:
    'On this page you can configure the query that is sent to ChatGPT \
    and define the audience-to-voice mapping. Fields left blank in \
    client-specific configurations will revert to the default.',

  configure_voices_query_label: 'ChatGPT Query',
  configure_voices_query_description:
    'Use the placeholder {{##VOICE##}} to indicate where the voice should be substituted.',

  configure_voices_defaults_header: 'Audience to Voice mapping',
  configure_voices_threshold_label: 'Minor voices threshold',
  configure_voices_threshold_description:
    'The percentage at which a secondary voices will activate.',

  configure_voices_success_default:
    'Default Voice Configuration updated successfully.',
  configure_voices_success_client:
    'Voice Configuration for {name} updated successfully.',
  configure_voices_error: 'Unable to update Voice Configuration.',

  // AUDIENCES
  audience_0: 'Masters of the Universe',
  audience_1: 'Visionaries',
  audience_2: 'Disruptors',
  audience_3: 'Chief Fun Officers',
  audience_4: 'Hearts & Souls',
  audience_5: 'Campaigners',

  // CLIENT
  client_error_get_client: 'Unable to get client.',
  client_error_get_countries: 'Unable to get countries list.',
  client_label_active: 'Active',
  client_label_name: 'Name',
  client_placeholder_name: 'Name',
  client_success_created: 'Client created.',

  clients_list_return: 'Return to clients list',

  edit_client_add_country_error: 'Unable to assign access to countries.',
  edit_client_add_country_success: 'Assigned access to {countryName}.',
  edit_client_error: 'Unable to update client details.',
  edit_client_header: 'Edit Client',
  edit_client_introduction: 'You may configure the client here.',
  edit_client_countries_allow_all: 'Allow all',
  edit_client_countries_header: 'Countries',
  edit_client_countries_introduction:
    "The list of countries which the client has access to. This determines the survey data which the client's users \
    may view, and which content they may access from the Inspiration Database.",
  edit_client_description_name: 'The name of the client.',
  edit_client_success: 'Successfully updated client details.',
  edit_client_remove_country_error: 'Unable to remove access to country.',
  edit_client_remove_country_success: 'Removed access to {countryName}.',
  edit_client_add_all_country_success:
    'Allowed client access to all countries.',
  edit_client_add_all_country_error: 'Unable to add access to countries.',

  new_client_create: 'Create Client',
  new_client_description_active:
    'Whether the new client is active or not. Users of inactive clients may not log in.',
  new_client_description_name: 'The name of the new client.',
  new_client_error_creating_client: 'Unable to create new client.',
  new_client_header: 'New Client',
  new_client_introduction: 'Create a new client.',
  new_client_user_create_success: 'Client user created.',

  client_age_restriction: 'Age restriction',
  client_age_restriction_none: 'None',
  client_age_restriction_lda: 'Under legal drinking age',
  client_age_restriction_u21: 'Under 21',
  client_age_restriction_description:
    "Set a restriction to filter out underage respondents in survey data presented to all users under this client. Legal drinking age depends on the respondent's country.",

  // CONTENT
  content_edit_global_content: 'Global Content',
  content_edit_global_content_description:
    'Global content is tagged with all countries. Note that if a new country is added to the system at a later date, \
    this content will need to be manually tagged with it. Additionally, if this is checked a "global" flag is set \
    against the content in the database.',
  content_edit_page_file: 'Image',
  content_edit_new_content_title: 'New Content',
  content_edit_page_file_pasted:
    'An image has been pasted into the content. See preview below.',
  content_edit_choose: 'Choose an image ...',
  content_edit_replace: 'Replace image ...',
  content_edit_image_pasted: 'Image pasted from clipboard.',
  content_edit_page_file_description:
    'Image should be 16:9 aspect and JPG format, with maximum dimensions 1920x1080 and maximum file size of 5Mb.',
  content_edit_page_description: 'Description',
  content_edit_preview_label: 'Preview',
  content_edit_save_success: 'Content {contentName} saved.',
  content_edit_save_fail: 'Could not save content {contentName}.',
  content_edit_publish_immediately: 'Publish immediately',
  content_edit_untitled_label: 'Untitled',
  content_edit_no_thumbnail: 'No thumbnail',
  content_edit_no_description: 'No description',
  content_edit_no_tags_found: 'No tags found',
  content_edit_no_tags: 'No tags',
  content_edit_none: 'None',
  content_edit_selected_tags: 'Selected tags',
  content_edit_select: 'Select',
  content_edit_selected: 'Selected',
  content_edit_add_tags: 'Add tags',
  content_edit_add_tags_desciption:
    'Select tags to add to the content. Selecting a genre will automatically include its associated category.',
  content_edit_add_tags_no_tags: 'No tags selected.',
  content_edit_login_page: 'Available for login page',
  content_edit_login_page_warning:
    'The login page appears prior to user authentication. This option should only be enabled if you are happy with \
    the content being publically viewable. The content must also be published to be selected.',
  content_edit_copyright_title: 'Copyright notice',
  content_edit_copyright_help: 'Shown on the landing page.',
  content_edit_no_tags_selected:
    'You have not added any genre tags to this content. Without genre tags this content cannot be viewed by users.',
  content_edit_no_country_selected:
    'You have not added any country tags to this content.',
  content_edit_save_anyway: 'Save anyway',

  // CONTENTS
  contents_introduction:
    'This shows all of the contents in the system. You may remove clients, and publish and unpublish them.',
  contents_list_content_description: 'Description',
  contents_no_contents: 'No contents found.',
  contents_error_delete_content: 'Unable to delete {contentName}.',
  clents_error_get_contents: 'Unable to get contents.',
  contents_error_set_content_lock: 'Unable to lock {contentName}.',
  contents_error_set_content_unlock: 'Unable to unlock {contentName}.',
  contents_header: 'Contents',
  contents_list_content_name: 'Content Name',
  contents_list_curator: 'Curator',
  contents_list_created_d: 'Date created',
  contents_list_published_d: 'Date published',
  contents_no_curator: '--none--',
  contents_modal_confirm_delete_title: 'Delete {contentName}',
  contents_modal_confirm_delete_text:
    'Are you sure you want to permanently delete {contentName}?',
  contents_new_content: 'New Content',
  contents_new_content_title: 'Create a new content.',
  contents_tag_metrics_link: 'Tag metrics',
  contents_tag_metrics_title: 'See content tags numbers.',
  contents_success_delete_content: '{contentName} deleted.',
  contents_title_content_delete: 'Delete content',
  contents_title_content_published_home: 'Available for login screen',
  contents_title_content_unpublished_home: 'Not avaiable for login screen',
  contents_list_content_curator: 'Curator',
  contents_published: 'Published',
  contents_unpublished: 'Unpublished',
  contents_success_published_home:
    '"{contentName}" may appear on the login screen if the content is published.',
  contents_success_unpublished_home:
    '"{contentName}" will no longer appear on the login screen.',
  contents_error_set_publish_home: 'Unable to pudate the content settings.',
  contents_filter_content: 'Filter by search term',
  contents_filter_curator: 'Filter by curator',
  contents_filter_published: 'Filter by published',
  contents_clear_filter_content: 'Clear',

  // REPORTS
  reports_header: 'Reports',
  reports_introduction:
    "This shows user usage information, including IP address and session details.\
    You may filter the logs by name of user, IP address, and date. A session will be displayed if it was active any time during the date range specified; you may specify a range up to a maximum of 30 days. \
    Sessions lapse after 15 minutes of inactivity, when the user's IP address changes, when a user logs out, and when the user uses their browser's refresh button. A new session will begin upon their next interaction with the site.\
    You may download the data in CSV format. The CSV data will be identical to the data showed in the table, including any applied filters.",
  reports_no_logs: 'No logs found for this date.',
  reports_download_csv: 'Download CSV',
  reports_list_name: 'User name',
  reports_list_ip: 'IP address',
  reports_list_timestamp: 'Timestamp',
  reports_month: 'Month',
  reports_year: 'Year',
  reports_months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  reports_error_get_logs: 'Unable to get logs',
  reports_error_get_csv: 'Unable to generate CSV.',
  reports_show_filters: 'Show filters',
  reports_hide_filters: 'Hide filters',
  reports_filter_ip:
    'Click an IP address in the list to show entires only for that address.',
  reports_list_session_start: 'Session start',
  reports_list_last_seen: 'Last seen',
  reports_list_session_length: 'Session length',
  reports_filter_zero_session: 'Show zero-length sessions',
  reports_filter_zero_session_help:
    'If a user logs in and then does not interact further with the site before their session is renewed, the length of that session will be zero.',
  reports_filter_start_date: 'Start date',
  reports_filter_end_date: 'End date',
  reports_user_ips_name: 'User name',
  reports_user_ips_label: 'IPs',
  reports_user_ips_desc:
    'This user has accessed the site from the following IP addresses during the specified date range (including zero-length sessions)',
  reports_user_ips_total: 'Total',
  reports_date_range_too_big:
    'Start and end dates can be no more than one month apart.',
  reports_csv_validation_error:
    'There are filter validation errors. Resolve these and try again.',

  // USERS
  users_error_delete_user: 'Unable to delete {userName}.',
  users_error_get_users: 'Unable to get users.',
  users_error_set_user_lock: 'Unable to lock {userName}.',
  users_error_set_user_unlock: 'Unable to unlock {userName}.',
  users_header: 'Users',
  users_introduction:
    "This shows all of the users in the system. You may remove users and set them locked (preventing them from \
    logging in). Click on the user's name to edit their details.",
  users_list_return: 'Return to users list',
  users_list_user_client: 'Client',
  users_list_user_name: 'User Name',
  users_list_user_role: 'Role',
  users_modal_confirm_delete_title: 'Delete {userName}',
  users_modal_confirm_delete_text:
    'Are you sure you want to permanently delete {userName}?',
  users_modal_confirm_lock_text:
    'Are you sure you want to lock {userName}? They will no longer be able to log in.',
  users_modal_confirm_lock_title: 'Lock {userName}',
  users_modal_confirm_unlock_text:
    'Are you sure you want to unlock {userName}? They will be able to log in.',
  users_modal_confirm_unlock_title: 'Unlock {userName}',
  users_download_emails: 'Download email addresses',
  users_new_user: 'New User',
  users_new_user_title: 'Create a new user.',
  users_no_users: 'No users found.',
  users_success_delete_user: '{userName} deleted.',
  users_success_set_user_lock: '{userName} locked.',
  users_success_set_user_unlock: '{userName} unlocked.',
  users_title_user_delete: 'Delete user',
  users_title_user_locked: 'User locked. Click to unlock.',
  users_title_user_unlocked: 'User unlocked. Click to lock.',

  // USER
  edit_user_description_active:
    'Whether the user is active or not. Inactive users may not log in.',
  edit_user_description_client:
    'The client which the user is a member of. Note that curators and superadmins have no client.',
  edit_user_description_email:
    "The user's e-mail address. This cannot be shown for security reasons.",
  edit_user_description_name: "The user's name.",
  edit_user_description_password:
    'The password which the user should use to log in. You should make a note of this and issue it to the user.',
  edit_user_description_role: "The user's role",
  edit_user_error: 'Unable to update user details.',
  edit_user_error_email_already_in_user:
    'Unable to update user. The e-mail address you provided is already in use.',
  edit_user_header: 'Edit User',
  edit_user_introduction:
    'This page allows you to modify editable user details.',
  edit_user_label_active: 'Active',
  edit_user_label_client: 'Client',
  edit_user_label_email: 'E-mail address',
  edit_user_label_role: 'Role',
  edit_user_label_name: 'Name',
  edit_user_label_password: 'Password',
  edit_user_password_requirement_intro: 'The new password must:',
  edit_user_placeholder_email: 'E-mail address',
  edit_user_placeholder_name: 'Name',
  edit_user_placeholder_password: 'Password',
  edit_user_save_role_client: 'Save Role and Client',
  edit_user_success: 'Successfully updated user details.',

  new_user_create: 'Create User',
  new_user_description_active:
    'Whether the new user is active or not. Inactive users may not log in.',
  new_user_description_client:
    'The client which the new user is a member of. Note that curators and superadmin have no client.',
  new_user_description_email: "The new user's e-mail address.",
  new_user_description_name: "The new user's name.",
  new_user_description_password:
    'The password which the new user should use to log in. You should make a note of this and issue it to the user.',
  new_user_description_role: "The new user's role.",
  new_user_error_creating_user: 'Unable to create new user.',
  new_user_error_email_already_in_user:
    'Unable to create new user. The e-mail address you provided is already in use.',
  new_user_header: 'New User',
  new_user_header_for_client: 'New User for {clientName}',
  new_user_introduction: 'Create a new user. All fields are required.',
  new_user_introduction_client_forced:
    'Please add a user for the client you just created. All fields are required.',
  new_user_label_active: 'Active',
  new_user_label_client: 'Client',
  new_user_label_email: 'E-mail address',
  new_user_label_name: 'Name',
  new_user_label_password: 'Password',
  new_user_label_role: 'Role',
  new_user_password_requirement_intro: 'The new password must:',
  new_user_placeholder_email: 'E-mail address',
  new_user_placeholder_name: 'Name',
  new_user_placeholder_password: 'Password',

  user_error_get_data: 'Unable to get required data',
  user_error_get_clients: 'Unable to get clients.',
  user_error_get_password_policy: 'Unable to get password policy.',
  user_error_get_user: 'Unable to get user data.',
  user_success_created: 'User created.',

  // FOOTER
  footer_copyright: 'Copyright &copy; PaddleConsulting.com 2023',
  footer_policy_pages: 'Site usage policy',

  // LOGIN
  login_form_back_to_fubi_homepage: 'Back to FUBI homepage',
  login_form_forgot_password: 'Forgot Password?',
  login_form_login: 'Log In',
  login_form_placeholder_username: 'E-mail address ...',
  login_form_placeholder_password: 'Password ...',
  login_form_remember: 'Remember Me',
  login_form_unable_to_log_in: 'Unable to log in:',
  login_welcome_to: 'Welcome to the Fubilizer',
  login_error_client_locked: 'Your client may be locked.',
  login_error_client_generic: 'There was a problem signing in.',

  // FORGOT PASSWORD
  forgot_password_form_alert_initiate_failure:
    'We were unable to send you a reset code.',
  forgot_password_form_alert_initiate_success:
    'A password reset code has been sent to your e-mail address. Please enter it into the form below and then set \
    your new password.',
  forgot_password_form_alert_reset_failure:
    'We were unable to reset your password.',
  forgot_password_form_alert_reset_success_1:
    'Your password has been reset. Please return to the homepage to log in.',
  forgot_password_form_alert_reset_success_2: 'Go to the homepage ...',
  forgot_password_form_description_code:
    'The reset code which was sent to you by e-mail.',
  forgot_password_form_description_email: 'Your account e-mail address.',
  forgot_password_form_description_password: 'Your new password.',
  forgot_password_form_already_got_code: 'I already have a reset code ...',
  forgot_password_heading: 'Forgot Password',
  forgot_password_form_placeholder_code: 'Reset code',
  forgot_password_form_placeholder_email: 'Your e-mail address',
  forgot_password_form_placeholder_password: 'New password',
  forgot_password_form_submit_get_code: 'Get reset code',
  forgot_password_form_submit_reset: 'Reset password',
  forgot_password_instructions:
    'Resetting your password is a two-stage process. First, please enter the e-mail address registered to your \
    account. You will then be sent a code which you can use to reset your password.',

  // MENU
  menu_clients: 'Clients',
  menu_contents: 'Content',
  menu_curation: 'Curation',
  menu_tags: 'Tag metrics',
  menu_log_out: 'Log Out',
  menu_profile: 'Profile',
  menu_reports: 'Reports',
  menu_saved_queries: 'Saved Series',
  menu_superadministration: 'Administration',
  menu_surveys: 'Surveys',
  menu_users: 'Users',

  // DASHBOARD
  dashboard_warning_no_countries:
    'Your client has not been assigned access to any survey data. Please contact Paddle Consulting for help.',
  dashboard_countries_get_error: 'Unable to fetch allowed countries.',
  dashboard_surveys_get_error: 'Unable to get surveys.',
  dashboard_survey_get_error: 'Unable to get survey {0}.',
  dashboard_series_get_error: 'Unable to get series.',
  dashboard_series_save_success: 'Series {0} has been saved.',
  dashboard_series_save_error: 'Unable to save series {0}.',

  // INSPIRATION PAGE
  inspiration_page_error_get_content: 'Unable to get content.',
  inspiration_page_list_empty: 'No content found',
  inspiration_page_success_set_bookmark: `'{contentName}' bookmarked successfully.`,
  inspiration_page_success_unset_bookmark: `Bookmark on '{contentName}' removed successfully.`,
  inspiration_page_error_set_bookmark: `Unable to bookmark '{contentName}'.`,
  inspiration_page_error_unset_bookmark: `Unable to remove bookmark on '{contentName}'.`,
  inspiration_page_filter_bookmarked: 'Bookmarked',
  inspiration_page_bookmarks_title: 'Bookmarks',
  inspiration_page_recommendation_title: 'Recommended Content',
  inspiration_page_recommendation_filter_global: 'Global recommendations',
  inspiration_page_recommendation_filter_global_disabled:
    'To see global recommendations, a series must have at least one country parameter and one other non-country parameter.',
  inspiration_page_bookmark_tooltip: 'Click to bookmark this Content',
  inspiration_page_unbookmark_tooltip: 'Click to unbookmark this Content',

  // QUERIES PAGE
  queries_page_introduction:
    'This page shows all of the FUBI Wheel series which have been created by users in your organisation.\
      Clicking the name of a series will take you to the dashboard with that series loaded. \
      You may generate a PowerPoint analysis on series that are non-manual and contain references to countries.',
  queries_page_title: 'Manage series',
  queries_page_list_empty: 'No series found',
  queries_page_edit_label: 'click to edit',
  queries_page_edit_name_title: 'Edit name of series',
  queries_page_error_blank_name: 'Name cannot be blank',
  queries_page_view_query_button: 'View series',
  queries_page_view_query_normalise_label: 'Normalise',
  queries_page_view_query_query_subheader: 'Query',
  queries_page_view_query_field_label: 'Field',
  queries_page_view_query_relation_label: 'Relation',
  queries_page_view_query_value_label: 'Value',
  queries_page_view_query_operator_label: 'Operator',
  queries_page_view_query_title: 'Series data for {0}',
  queries_page_view_query_message:
    'Are you sure you want to permanently delete this series?',
  queries_page_delete_title: 'Delete {0}',
  queries_page_delete_message:
    'Are you sure you want to permanently delete this series?',
  queries_page_delete_ok: 'Yes, delete',
  queries_page_delete_cancel: "No, don't delete",
  queries_page_error_get_series: 'Unable to get series.',
  queries_page_success_change_name: 'Series name changed to {0}.',
  queries_page_error_change_name: 'Unable to change series name.',
  queries_page_success_delete: 'Series {0} has been deleted.',
  queries_page_error_delete: 'Unable to delete series {0}.',
  queries_page_title_series_delete: 'Delete series',
  queries_page_title_name_edit: 'Change series name',
  queries_page_title_recommended: 'Recommended Content',

  // POWERPOINT
  powerpoint_title_analysis: 'Generate PowerPoint',
  powerpoint_modal_title: 'Generate a PowerPoint analysis',
  powerpoint_modal_instructions:
    'Select the content which you would like to be included in the PowerPoint by selecting it from the lower table. You may filter the content by \
    country and genre. Hover over the name of a content item to see more information.',
  powerpoint_modal_no_selected_contents:
    'No contents selected to attach. You may select some below.',
  powerpoint_contents_list_content_tags: 'Content tags',
  powerpoint_contents_list_content_global_recommendations:
    'Global recommendations',
  powerpoint_contents_list_content_all: 'All',
  powerpoint_contents_list_content_bookmarks: 'Bookmarks',
  powerpoint_contents_list_content_recommended: 'Recommended',
  powerpoint_modal_definitions_select:
    'Select the FUBI definitions which you would like to be included in the PowerPoint by clicking them below. \
    Hover over each to see its definition.',
  powerpoint_modal_generating:
    'Generating PowerPoint... download will begin automatically.',
  powerpoint_generate_button: 'Generate',
  powerpoint_text_global_title: 'Global preference',
  powerpoint_profile_headers: ['FUBI Profile', 'Total', 'Sample', 'DIFF'],
  powerpoint_attitudes_headers: ['Attitudes', 'Total', 'Sample', 'DIFF'],
  powerpoint_affinities_headers: ['Affinities', 'Total', 'Sample', 'DIFF'],
  powerpoint_employment_headers: [
    'Employment status',
    'Total',
    'Sample',
    'DIFF',
  ],
  powerpoint_gender_headers: ['Gender', 'Total', 'Sample', 'DIFF'],
  powerpoint_age_headers: ['Age', 'Total', 'Sample', 'DIFF'],
  powerpoint_content_description: 'Related content',
  powerpoint_definition_description: 'FUBI Definitions',
  powerpoint_success_message: 'PowerPoint generated successfully.',
  powerpoint_generation_fail: 'Unable to generate PowerPoint.',
  powerpoint_blurb: '[ replace with your description ]',
  powerpoint_content_filter_country: 'Filter by country',
  powerpoint_content_filter_genre: 'Filter by genre',

  // WHEEL WRAPPER
  wheel_modal_save_series_title: 'Name this series',
  wheel_modal_save_series_dont_save: 'Continue without saving',
  wheel_modal_load_series_title: 'Add series to chart',
  wheel_modal_load_series_error_blank: 'Select a series',
  wheel_modal_load_manual_series_nb: 'manual series',

  // TAG METRICS
  tag_report_header: 'Tag usage metrics',
  tag_report_introduction:
    'This table shows a count of content items to which each genre tag has been assigned. You may filter by tag category and country. You may also download a CSV file containing all data in a matrix format (unaffected by filters).',
  tag_report_download_csv: 'Download CSV',
  tags_list_tag_name: 'Genre tag name',
  tags_list_tag_count: 'Contents with this tag',
  tag_report_no_tags: 'No tags found',
  tag_filter_unpublished: 'Count unpublished content',
  tags_list_all_countries: 'All countries',

  // PROFILE PAGE
  profile_page_cancel_change_password: 'Cancel change password',
  profile_page_change_password: 'Change password',
  profile_page_name_title: 'Your profile',
  profile_page_name_label: 'Name',
  profile_page_current_password_label: 'Current password',
  profile_page_new_password_label: 'New password',
  profile_page_new_password_retype_label: 'Re-type new password',
  profile_page_success_save: 'Profile saved',
  profile_page_error_save_NotAuthorizedException:
    'Your current password is not correct.',
  profile_page_error_save_LimitExceededException:
    // Annoyingly, AWS explicitly will not tell you exactly how long "a while" is...
    'You have attempted to change your password too many times. Please wait a while and try again.',
  profile_page_error_save_InvalidParameterException:
    'Your new password does not comply with the password policy.',
  profile_page_validation_error_current_password_min_6:
    'Current password must be at least 6 characters.',
  profile_page_validation_error_password_retype_doesnt_match:
    'Passwords do not match.',
  profile_page_validation_must_include: 'Your new password must:',
  profile_page_validation_min_length: 'be at least {0} characters long',
  profile_page_validation_uppercase: 'contain at least one uppercase character',
  profile_page_validation_lowercase: 'contain at least one lowercase character',
  profile_page_validation_special: 'contain at least one special character',
  profile_page_validation_number: 'contain at least one number',

  // SURVEYS
  surveys_error_get_surveys: 'Unable to get surveys.',
  surveys_header: 'Surveys',
  surveys_introduction:
    'Currently only one survey is available. You may modify data for individual countries contained within the survey \
    by clicking through to the survey edit page.',
  surveys_list_return: 'Return to surveys list',
  surveys_list_survey_name: 'Name',
  surveys_no_surveys: 'No surveys found.',

  survey_error_create_country: 'Unable to create country {countryName}.',
  survey_error_create_country_s3_upload:
    'Unable to create country {countryName}. There was a problem uploading the data files.',
  survey_error_delete_country: 'Unable to delete country {countryName}.',
  survey_error_edit_country: 'Unable to update data files for {countryName}.',
  survey_error_edit_country_s3_upload:
    'Unable to update data files for {countryName}. There was a problem uploading the data files.',
  survey_error_get_countries: 'Unable to get countries.',
  survey_error_get_survey: 'Unable to get survey.',
  survey_list_survey_name: 'Country',
  survey_modal_confirm_delete_country_text:
    'Are you sure you want to delete {countryName}? All clients will lose access to the country, and content will \
    cease to be tagged with it.',
  survey_modal_confirm_delete_country_title: 'Delete Country {countryName}',
  survey_no_countries: 'No countries found',
  survey_success_create_country: 'Created {countryName}.',
  survey_success_delete_country: 'Deleted {countryName}.',
  survey_success_edit_country: 'Updated data for {countryName}.',
  survey_title_country_delete: 'Delete country',
  survey_title_country_edit: 'Edit country',

  edit_survey_add_country: 'Add Country',
  edit_survey_edit_country: 'Update Country Data',
  edit_survey_header: 'Edit Survey',
  edit_survey_introduction:
    'You may add or remove country data for the survey. Note that if you remove a country, the corresponding tag will \
    be removed from the Inspiration Database. Content will cease to be tagged with that country.',
  survey_edit_label_country_name: 'Country Name',
  survey_edit_description_country_name:
    'The name of the country whose data is to be added.',
  survey_edit_placeholder_country_name: 'Country name ...',
  survey_edit_label_file_responses: 'Survey Responses',
  survey_edit_description_file_responses:
    'Survey responses. Requires a .txt file.',
  survey_edit_label_file_synonyms: 'Survey Synonyms',
  survey_edit_description_file_synonyms:
    'Survey synonyms. Requires a .json file.',
  survey_edit_label_file_questions: 'Survey Questions',
  survey_edit_description_file_questions:
    'Survey questions. Requires a .xml file.',
  survey_edit_label_file_weights: 'Survey Weights',
  survey_edit_description_file_weights:
    'Survey weightings. Requires a .csv file.',
  survey_edit_placeholder_data_file:
    'Choose a file or drop it here ({fileType}) ...',
  survey_edit_placeholder_drop_data_file: 'Drop file here ({fileType}) ...',

  // GLOSSARY
  glossary_you_know_preamble: "You know it's <!!> when", // <!!> will be replaced with coloured genre box
  glossary: {
    Funny: {
      Slapstick: {
        description:
          'is one of the sillier, simpler forms of comedy; the humour derives from its larger-than-life physical gesturing, movements, and situations.',
        youKnow:
          "it's physical, people move in an exaggerated way, fall over or get hit with things.",
      },
      Deadpan: {
        description:
          'is dry humour defined more by its delivery than content delivered in a rigid, monotonous, bored tone, making the joke scathingly blunt or sarcastic.',
        youKnow: "there's little or no emotion in the performance.",
      },
      ['Self-Deprecating']: {
        description:
          'is humour that relies on belittling oneself with jokes that most people can relate to and laugh at their is dry humour defined more by its delivery than content delivered in a rigid, monotonous, bored tone, making the joke scathingly blunt or sarcastic.',
        youKnow: "the jokes are about the performer's inadequacies.",
      },
      Parody: {
        description:
          "mocks something or someone that's well-known in culture by twisting or exaggerating its characteristics. ",
        youKnow:
          "it mocks through exaggeration but doesn't make a larger social point(that's satire).",
      },
      Outrageous: {
        description:
          'is low brow humour that relies heavily on being gross, focusing on poor taste, vulgarity, and “crossing the line” for the sake of making the audience cringe.',
        youKnow: "it's funny because it's gross.",
      },
      Satire: {
        description:
          'is smart humour that highlights real-life hypocrisies and flaws, often mocking current events.',
        youKnow:
          "it uses irony, sarcasm and exaggeration to make a relevant social commentary. (If there's no social commentary, it's parody.)",
      },
      Surreal: {
        description:
          'leans towards the bizarre using nonsensical situations and focusing in on the ridiculous to the point of absurdity.',
        youKnow: 'it defies logic and uses absurd non-sequiturs.',
      },
      Dark: {
        description:
          'focuses on serious, dark, and often morbid subject matter—when things are bad, the only thing you can do is laugh.',
        youKnow: 'it finds true humour in devastating situations.',
      },
    },

    Useful: {
      Trivia: {
        description:
          'is a nugget of information that you do not need to know but is interesting anyway.',
        youKnow: 'it could be the answer to a question in a pub quiz.',
      },
      ['Authoritative opinion']: {
        description:
          'carries weight or endorsement supported by documentary evidence and accepted by experts.',
        youKnow:
          'a trusted source (individual or institution) gives a judgement about something.',
      },
      ['Consumer reviews']: {
        description:
          'is customer feedback of a product or service made by a customer who has purchased it.',
        youKnow:
          'it features crowdsourced opinions on the quality of a product or service.',
      },
      Hotlists: {
        description:
          'assembles and shares advice, often in the form of lists, of enriching activities.',
        youKnow:
          'it tells you what to see, listen to, eat or otherwise guides your cultural life.',
      },
      ['Handy tips']: {
        description:
          'are informal, often short and greatly simplified step-by-step guides meant to help non-experts.',
        youKnow:
          "it sets out clear instructions for accomplishing a specific task. (If it's more general, it's Inspiring/Life Coaching)",
      },
      ['News analysis']: {
        description:
          'has experts discussing the news from all angles, giving perspective and addressing consequences.',
        youKnow:
          'it puts news stories in context and interprets them to form a point of view',
      },
      ['Search/Answer']: {
        description:
          "uses the internet's resources to ask questions and get responses.",
        youKnow: 'it finds you the answer to a specific question.',
      },
      ['News updates']: {
        description:
          'are the latest headlines and updates on news from around the world.',
        youKnow:
          "it's a breaking story about events as they happen but don't analyse or put them in context.",
      },
    },
    Beautiful: {
      Spontaneous: {
        description:
          'captures a fleeting moment, something emerging out of nothing, a surprise.',
        youKnow:
          "the image couldn't have been taken a second before, or a moment afterwards.",
      },
      ['Stylised/Abstract']: {
        description:
          "is imagery that doesn't try to represent reality. It may be completely or partially abstract.",
        youKnow:
          "it doesn't try to echo the real world, it creates its own reality.",
      },
      Pop: {
        description:
          'is imagery from popular and mass culture, such as advertising and mundane cultural objects.',
        youKnow:
          'it contains popular culture and commercial references, eg comics, music, commercial design.',
      },
      Minimalist: {
        description:
          'is a style or technique that is characterized by extreme spareness and simplicity and the use of simple, massive forms.',
        youKnow:
          'design is pared down to its functional elements and everything else is removed.',
      },
      Extravagant: {
        description:
          'is art that exceeds the bounds of reason and goes beyond what is necessary or justifiable.',
        youKnow:
          "it's the boldest possible statement, it's unrestrained and flamboyant.",
      },
      Ethereal: {
        description:
          'is art that feels extremely fragile, fleeting and light in a way that seems not to be of this world.',
        youKnow: "it's delicate and otherworldly.",
      },
      Cute: {
        description: 'is attractive in a pretty or endearing way.',
        youKnow: 'your first reaction is to go “Aaaah…”.',
      },
      Sensual: {
        description:
          'is pleasing to the body and the senses and physical, especially sexual, pleasure.',
        youKnow: 'it arouses feelings of physical pleasure.',
      },
    },

    Inspiring: {
      Heartwarming: {
        description:
          'is tales or moments with a positive message causing feelings of pleasure and happiness.',
        youKnow: 'a positive story is emotionally rewarding and uplifting.',
      },
      Enraging: {
        description:
          'is a story or opinion calculated to anger an audience (though not necessarily you). Rage can be positive, e.g. anger at a social injustice, or negative e.g. hate stoked against an out-group',
        youKnow: 'a sizeable audience would be angered by it.',
      },
      ['Life-coaching']: {
        description: 'is practical advice for improving your wellbeing.',
        youKnow:
          "it gives you meaningful and actionable advice to improve your life. If it's abstract it's Inspiring/Spiritual, if it's very specific it's Useful/Handy Hints.",
      },
      ['Big Ideas']: {
        description:
          'are the ideas that could disrupt our lives for better or worse: paradigm shifts in technology, society, business or art',
        youKnow: "it's a new way of thinking that might change the world",
      },
      Spiritual: {
        description:
          "are concepts that address a person's inner life and go beyond material values or pursuits.",
        youKnow:
          'you find wisdom relating to the soul rather than material things.',
      },
      Thrilling: {
        description:
          'cause pleasure through a blast of exhilaration and excitement.',
        youKnow: ' it features action that gives you a rush of adrenaline.',
      },
      Wonder: {
        description: 'is a feeling of curiosity, amazement and admiration',
        youKnow:
          'it presents something remarkable to us that gives us a feeling of awe that makes us question our assumptions.',
      },
      ['Social good']: {
        description:
          'is goods or services that benefit the wider community with no personal agenda or profit motive.',
        youKnow:
          'it gives you practical advice on helping others rather than yourself.',
      },
    },
  },
};

export default lang;
