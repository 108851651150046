import Amplify, { Auth } from 'aws-amplify';
import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import config from '@/config/config.json';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { i18n } from '@/i18n/i18n';

import 'vue-slider-component/theme/antd.css';

// Set up Amplify
config.amplify.API.endpoints[0].custom_header = async () => {
  return {
    Authorization: `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`,
  };
};
Amplify.configure(config.amplify);

// Project style sheet.
// See https://bootstrap-vue.org/docs
import '@/scss/style.scss';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
