export default [
  { text: 'Art, Culture, & Nightlife', value: 'ANY: ART, CULTURE, NIGHTLIFE' },
  { text: 'Banking & Investment', value: 'ANY: BANKING AND INVESTMENT' },
  { text: 'Business', value: 'ANY: BUSINESS' },
  {
    text: 'Cars, Motorbikes, & Automobiles',
    value: 'ANY: CARS, MOTORBIKES, AUTO',
  },
  {
    text: 'Charity, Altruism, & Campaigning',
    value: 'ANY: CHARITY, ALTRUISM, CAMPAIGNING',
  },
  { text: 'Comics & Animation', value: 'ANY: COMICS AND ANIMATION' },
  { text: 'Cooking & Food', value: 'ANY: COOKING AND FOOD' },
  { text: 'Family-focused', value: 'ANY: FAMILY-FOCUSED' },
  { text: 'Fashion & Beauty', value: 'ANY: FASHION AND BEAUTY' },
  { text: 'Gadgets & Electronics', value: 'ANY: GADGETS AND ELECTRONICS' },
  { text: 'Gaming', value: 'ANY: GAMING' },
  { text: 'Green Living', value: 'ANY: GREEN LIVING' },
  { text: 'Health & Fitness', value: 'ANY: HEALTH AND FITNESS' },
  { text: 'Home Improvement', value: 'ANY: HOME IMPROVEMENT' },
  { text: 'Memes, Jokes, & Laughs', value: 'ANY: MEMES, JOKES AND LAUGHS' },
  { text: 'Movies', value: 'ANY: MOVIES' },
  { text: 'Music', value: 'ANY: MUSIC' },
  { text: 'News & Current Affairs', value: 'ANY: NEWS AND CURRENT AFFAIRS' },
  { text: 'Outdoors', value: 'ANY: OUTDOORS' },
  { text: 'Pets', value: 'ANY: PETS' },
  { text: 'Science & Technology', value: 'ANY: SCIENCE AND TECHNOLOGY' },
  { text: 'Shopping', value: 'ANY: SHOPPING' },
  { text: 'Social Media', value: 'ANY: SOCIAL MEDIA' },
  { text: 'Sports', value: 'ANY: SPORTS' },
  { text: 'Thrills & Spills', value: 'ANY: THRILLS AND SPILLS' },
  { text: 'Travel', value: 'ANY: TRAVEL' },
  { text: 'TV', value: 'ANY: TV' },
];
