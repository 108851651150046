/**
 * Fubifyer
 * Uses ChatGPT (via the backend) to rewrite a bit of text
 * as if it were written by a certain person.
 */

import Wizard from './Wizard.vue';
import { apiPost, apiPut } from '@/util/api';
export default {
  name: 'Fubify',
  components: {
    Wizard,
  },
  props: {
    series: Array,
    custom: { type: Boolean, default: false },
    countries: Array,
    audienceSize: Object,
    histogram: Array,
  },
  data: () => ({
    loading: false, // Loading webpage stuff
    generating: false, // Doing ChatGPT

    copy: '', // Input for ChatGPT
    output: '', // Output from ChatGPT
    lastUsedAudience: undefined, // The audience the user used last

    audiences: [], // Up to six audiences to populate rewrite buttons

    choosingReplacement: false, // Has clicked "edit audience" button
    replacingSeries: undefined, // Actively editing this series

    wizardQuery: undefined, // The query that the wizard has produced
  }),
  async created() {
    // Audience name and button colour for each default
    const defaults = [
      ['audience_0', '#45b'],
      ['audience_1', '#667'],
      ['audience_2', '#2a4'],
      ['audience_3', '#4ae'],
      ['audience_4', '#bb0'],
      ['audience_5', '#d21'],
    ];

    for (let i = 0; i < 6; i++) {
      const userSeries = this.series.find(ser => ser.audienceId === i);
      if (userSeries && userSeries.name)
        this.audiences.push({
          ...userSeries,
          colour: defaults[i][1],
          overwritten: true,
        });
      else if (!this.custom) {
        const defaultSeries = defaults[i];

        // For the default buttons, each represents one of the categories only,
        // so set that category to 1 and leave the rest as 0.
        const placeholderWeights = [0, 0, 0, 0, 0, 0];
        placeholderWeights[i] = 1;

        this.audiences.push({
          audienceId: i,
          name: this.$t(defaultSeries[0]),
          colour: defaultSeries[1],
          audienceWeights: placeholderWeights.join(','),
        });
      }
    }
  },
  methods: {
    async onAudienceClick(audience) {
      if (this.choosingReplacement) {
        this.replaceAudience(audience);
      } else {
        this.fubify(audience);
      }
    },

    async replaceAudience(series) {
      this.copy = undefined;
      this.output = undefined;
      this.lastUsedAudience = undefined;
      this.$emit('fubified', null);

      this.choosingReplacement = true;
      this.replacingSeries = series;
    },

    changeSeries(series) {
      if (series.id) {
        return apiPut(`/series/${series.id}`, series);
      } else {
        return apiPost('/series', series);
      }
    },

    async fubify(audience) {
      this.loading = true;

      const { audienceWeights, audienceId, overwritten } = audience;
      this.lastUsedAudience = audience;

      if (this.custom) {
        const response = await apiPost('/fubify/prompt', {
          copy: this.copy,
          id: audience.audienceId,
        })
          .catch(() => {
            this.$bvToast.toast('Unable to rewrite. Please try again.', {
              title: this.$t('common_error_title'),
              variant: 'danger',
            });
          });
        this.output = response?.message;
      } else {
        // Send an id off to the wheel only if it's not a default
        if (overwritten) {
          this.$emit('fubified', audienceId);
        } else {
          this.$emit('fubified', null);
        }

        const weightsArray = audienceWeights
          ?.split(',')
          .map(weight => parseFloat(weight));

        // Here we are assuming that as the weights array
        // will contain numbers to a high number of significant
        // figures, based on a really big survey calculation from
        // the wheel, the chances of two being the same is very tiny
        const majorWeight = Math.max(...weightsArray);
        const majorIndex = weightsArray.indexOf(majorWeight);

        weightsArray[majorIndex] = -1;

        const minorWeight = Math.max(...weightsArray);
        const minorIndex = weightsArray.indexOf(minorWeight);

        const response = await apiPost('/fubify', {
          major: { index: majorIndex, weight: majorWeight },
          minor: { index: minorIndex, weight: minorWeight },
          copy: this.copy,
          series: audienceId,
        }).catch(() => {
          this.$bvToast.toast('Unable to rewrite. Please try again.', {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });

        this.output = response.message;
      }
      this.loading = false;
    },

    async saveQuery(name, seriesString) {
      const series = this.replacingSeries;
      const newSeries = {
        id: series.id,
        audienceId: series.audienceId,
        name,
        series: seriesString,
        audienceWeights: this.histogram.join(','),
      };

      await this.changeSeries(newSeries)
        .then(() => {
          this.$bvToast.toast(`Audience ${name} saved.`, {
            title: this.$t('common_success_title'),
            variant: 'success',
          });
          Object.assign(this.audiences[series.audienceId], {
            ...newSeries,
            overwritten: true,
          });
          this.lastUsedAudience = undefined;
          this.replacingSeries = undefined;
          this.choosingReplacement = false;
          this.$emit('savedSeries', newSeries);
        })
        .catch(() => {
          this.$bvToast.toast(`Audience ${name} could not be saved.`, {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });

      // The whole wheel-exists-in-an-iframe thing is starting to become
      // somewhat bogged down. It's much easier to just reload the page.
      // location.reload();
    },
    onWizardQueryChange(payload) {
      this.$emit('queryChange', payload);
    },
    onWizardCancel() {
      this.choosingReplacement = false;
      this.replacingSeries = undefined;
    },
    getAudienceStyle(audience) {
      return {
        backgroundColor: audience.colour,
        borderColor: audience.colour,
      };
    },
    onRestartClick() {
      this.copy = undefined;
      this.output = undefined;
      this.lastUsedAudience = undefined;
      this.choosingReplacement = false;
    },
  },
};
