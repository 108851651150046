/**
 * Auth utilities.
 */
import store from '@/store';

/**
 * User roles. Used for route authorisation.
 */
export const UserRole = {
  ANY: 'ANY', // Any user role / logged-in status
  LOGGED_OUT: 'LOGGED_OUT', // User must be logged out
  SUPERADMIN: 'SUPERADMIN', // Paddle user; superadmin access (site, client and user management)
  CURATOR: 'CURATOR', // Paddle user; curation access (inspiration library content)
  ADMIN: 'ADMIN', // Client user; may manage users within the client (currently unused)
  USER: 'USER', // Client user; has access to standard platform functionality (wheel, inspiration library)
};

/**
 * Check whether the currently logged in user has superadmin-level authorisation.
 * @param {String} role
 * @returns {Boolean}
 */
export const userIsSuperadmin = () => {
  return store.state.user.role === UserRole.SUPERADMIN;
};

/**
 * Check whether the currently logged in user has curator-level authorisation.
 * @param {String} role
 * @param {Boolean}
 */
export const userIsCurator = () => {
  return [UserRole.SUPERADMIN, UserRole.CURATOR].includes(
    store.state.user.role,
  );
};

/**
 * Check whether the currently logged in user is authorised for the specified role.
 * @param {String} roleRequired
 * @returns {Boolean}
 */
export const userIsAuthorised = roleRequired => {
  // Any allowed role, including logged out.
  if (roleRequired === UserRole.ANY) {
    return true;
  }

  // If the user is not logged in then check that being logged out is permissible.
  if (!store.state.user.userId) {
    if (roleRequired === UserRole.LOGGED_OUT) {
      return true;
    } else {
      return false;
    }
  }

  // Handle logged-in user roles.
  switch (roleRequired) {
    case UserRole.SUPERADMIN:
      // Paddle superadmin role.
      return userIsSuperadmin();
    case UserRole.CURATOR:
      // Paddle curator role - includes superadmins.
      return userIsCurator();
    case UserRole.ADMIN:
      // Cleint admins. I don't think this is quite correct - probably need to include curators and superadmins.
      return store.state.user.role === UserRole.ADMIN;
    case UserRole.USER:
      // All logged in users have this level of access.
      return true;
  }

  // If none of the above, access is denied.
  return false;
};

/**
 * Test whether a password complies with a specified policy.
 * @param {*} passwordPolicy
 * @param {*} password
 */
export const passwordIsValid = (passwordPolicy, password) => {
  if (!passwordPolicy) return;
  if (!password) return;

  const compliance = {
    MinimumLength: password.length >= passwordPolicy.MinimumLength,
    RequireLowercase:
      !passwordPolicy.RequireLowercase || /(?=.*[a-z])/.test(password),
    RequireUppercase:
      !passwordPolicy.RequireUppercase || /(?=.*[A-Z])/.test(password),
    RequireNumbers:
      !passwordPolicy.RequireNumbers || /(?=.*[0-9])/.test(password),
    RequireSymbols:
      !passwordPolicy.RequireSymbols ||
      /(?=.*[\^$*.[\]{}()?\-"!@#%&\\/,><':;|_~`])/.test(password),
  };

  return {
    All:
      compliance.MinimumLength &&
      compliance.RequireLowercase &&
      compliance.RequireUppercase &&
      compliance.RequireNumbers &&
      compliance.RequireSymbols,
    ...compliance,
  };
};
