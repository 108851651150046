import Vue from 'vue';
import Vuex from 'vuex';
import { UserRole } from '@/util/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: { user: {}, loading: false, loaders: [], sessionId: '' },
  mutations: {
    /**
     * Set the currently logged in user.
     */
    setUser(state, user) {
      state.user = user;
    },
    /**
     * Clear the currently loged in user.
     */
    clearUser(state) {
      state.user = {};
      state.sessionId = '';
    },
    /**
     * Set the current sessionId.
     */
    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    /**
     * Set the global loader
     */
    setLoading(state, payload) {
      if (state.loaders.indexOf(payload) === -1) {
        state.loaders.push(payload);
      }
      state.loading = state.loaders.length > 0;
    },
    /**
     * Unset the global loader
     */
    unsetLoading(state, payload) {
      state.loaders.splice(state.loaders.indexOf(payload), 1);
      state.loading = state.loaders.length > 0;
    },
    /**
     * Set a content as bookmarked for this user
     */
    addBookmark(state, payload) {
      state.user.bookmarks.push(payload);
    },
    /**
     * Unset a content as bookmarked for this user
     */
    removeBookmark(state, payload) {
      state.user.bookmarks = state.user.bookmarks.filter(
        cId => cId !== payload,
      );
    },
  },
  getters: {
    userIsCuratorOnly: state => {
      return state.user.role === UserRole.CURATOR;
    },
    userIsCustomPrompts: state => {
      return state.user.client?.hasCustomPrompts;
    },
  },
  actions: {},
  modules: {},
});
